import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../DashboardNavbar";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import { Grid } from "@mui/material";
import localforage from "localforage";
import React, { useState, useEffect } from "react";
import Loader from "examples/Loader";
import Category from "./components/Category";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import env from "config";
import useRoutes from "../routes";
import { Params } from "react-router-dom";
import ThemeNavbar from "../ThemeNavbar/ThemeNavbar";
import SnapshotData from "./components/data";
import ConfirmAlert from "./components/ConfirmAlert";
import BuyAllAppModal from "./components/BuyAllAppModal";
function Snapshot() {
  // const [category_data, setcategory_data] = useState([]);
  const {
    snapshot_data,
    agency_data,
    location_data,
    purchased_apps,
    category_data,
    processing,
    handleReferesh,
  } = SnapshotData();
  const [handleGrid, sethandleGrid] = useState("grid");
  const navigate = useNavigate();
  const [refresh, setrefresh] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [start_num, setstart_num] = React.useState("");
  const [end_num, setend_num] = React.useState("");
  const [text, settext] = React.useState("");
  const [cat_id, setcat_id] = useState("");
  const routes = useRoutes();
  const Params = useParams();
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const BASE_URL = `${env.API_URL}/v1`;
  const handleOnLoad = () => {
    handleReferesh();
    setrefresh(!refresh);
  };
  useEffect(async () => {
    var cat_ids = pathname.substring(pathname.indexOf("/Snapshot") + 10);
    setcat_id(cat_ids);
  }, [pathname, refresh]);
  const handleview = (type) => {
    // alert(type)
    if (type == "grid") {
      sethandleGrid("grid");
    } else {
      sethandleGrid("list");
    }
  };
  const handlerange = (start_num, end_num) => {
    setstart_num(start_num);
    setend_num(end_num);
  };
  const handleAlert = async (text, alert_type) => {
    const localBrand = await localforage.getItem("loc_data");
    setState({ ...state, open: true });
    settext(text);
    // alert(alert_type)
    if (alert_type == "auth") {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/auth`
        );
      }, 2000);
    } else if (alert_type == "session key") {
      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/setting
s/payment`
        );
      }, 2000);
    }
  };

  return (
    <DashboardLayout>
      {processing ? (
        <Loader />
      ) : (
        <>
          <DashboardNavbar
            routes={routes}
            handleview={handleview}
            handlerange={handlerange}
            handelrefresh={handleOnLoad}
          />
          <ConfirmAlert handleSubmit={handleAlert} state={state} text={text} />

          {/*
        <ThemeNavbar handleview={handleview} handlerange={handlerange} handelchange={handleOnLoad} /> */}
          <Grid container mt={8}>
            {/* <Grid item xs={12} sx={{ textAlign: "right" }}>
              <MDBox mb={3}>
                <BuyAllAppModal
                  handleAlert={handleAlert}
                  handlerefresh={handleOnLoad}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12}>
              <MDBox mb={3}>
                <Category
                  color="info"
                  icon="weekend"
                  title="Category"
                  cat_id={cat_id}
                  handelchange={handleOnLoad}
                  handleAlert={handleAlert}
                  data={snapshot_data}
                  purchased_apps={purchased_apps}
                  category_data={category_data}
                  agency_data={agency_data.data}
                  location_data={location_data}
                  handleGrid={handleGrid}
                />
              </MDBox>
            </Grid>
          </Grid>
        </>
      )}
    </DashboardLayout>
  );
}

export default Snapshot;
