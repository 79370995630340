import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "qs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ReqMethods, webinar } from "enums";
import env from "config";
import { useAppServices } from "hook/services";
import { useBrandInfo } from "context/brand";
import localforage from "localforage";
// import { AgencyContext } from '../../../context/Agency.context'

function Auth() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const client_id = new URLSearchParams(search).get("client_id");
  const client_secret = new URLSearchParams(search).get("client_secret");
  const redirect_url = new URLSearchParams(search).get("redirect_uri");
  const state = new URLSearchParams(search).get("state");
  const AppServices = useAppServices();
  const [Error, setError] = useState("");
  const [brand, update] = useBrandInfo();
  // const app_id = state_data.split(",")[0];
  // const locationId = state_data.split(",")[1];
  // // const agency_id = state_data.split(",")[2];
  // const type = state_data.split(",")[2];
  // const associate = async (creds) => {
  //   const payload = {
  //     app_id: app_id,
  //     locationId: locationId,
  //     type: type,
  //     app_configuration: {
  //       access_token: creds.access_token,
  //       expires_in: creds.expires_in,
  //       principal: creds.principal,
  //       refresh_token: creds.refresh_token,
  //       token_type: creds.token_type,
  //     },
  //   };

  //   console.log(payload, "associate payload");
  //   const { response } = await AppServices.location_apps.update({ payload });
  //   console.log(response, "associate response");

  //   if (!response)
  //     return setError(
  //       "Something went wrong while in associate integration, please try latter"
  //     );
  //   return navigate(-1);
  // };
  const getCreds = async () => {
    const payload = {
      client_id: client_id,
      client_secret: client_secret,
      redirect_uri: redirect_url,
      grant_type: "authorization_code",
    };
    console.log(payload, "getCreds");

    const { response } = await AppServices.location_apps.getapikey({
      payload,
    });
    console.log(response, "response");

    if (!response) {
      window.location.href = `${redirect_url}?code=null&status=404&state=${state}`;
    } else {
      window.location.href = `${redirect_url}?code=${response.data._id}&status=200&state=${state}`;
    }
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')
    // return response && associate(response.data);
  };

  const onLoad = () => {
    console.log("codecodecodecodecodecodecodecode");
    getCreds();
  };

  useEffect(onLoad, []);

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default Auth;
