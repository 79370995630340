import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";

const BuyModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const AppService = useAppServices();
  const [brand, setbrand] = useState([]);
  const [stripe_data, setstripe_data] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const location_settings = useAgencyInfo()[5];

  const inputRef = useRef();
  var path = pathname.split("/")[1];
  var loc_name = pathname.split("/")[5].replace(/\s/g, "");
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = pathname.split("/")[6].replace(/\s/g, "");
  var axios = require("axios");
  let navigate = useNavigate();

  const handlemodal = async (newState) => {
    if (brand == null) {
      const localBrand = await localforage.getItem("loc_data");
      props.handleAlert(
        "for purchase a snapshot make sure you must be login!",
        "auth"
      );
    } else {
      setOpenAddProduct(true);
    }
  };
  const getStripe = async (e) => {
    const user_data = await localforage.getItem("brand");
    const { response } = await AppService.stripe.filter({
      id: user_data._id,
    });
    if (response) {
      setstripe_data(true);
    } else {
      setstripe_data(false);
    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    // alert(brand_data)
    setbrand(brand_data);
    getStripe();
  }, []);
  function AddProduct({ handleAlert, open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

      setProcessing(true);

      const brand = await localforage.getItem("brand");
      const loc_data = await localforage.getItem("loc_data");
      // alert(loc_data.location_id)
      const snapshot = props.data;
      if (stripe_data == true || snapshot.payable == "no") {
        const payload = {
          snapshot: snapshot,
          loc_name: loc_name,
          loc_add: loc_add,
        };
        const { response } = await AppService.snapshot.load_snapshot({
          payload,
          user_id: brand._id,
          agency_id: loc_data.agency_id,
          location_id: loc_data.location_id,
        });
        if (response) {
          props.handleMessage(response.data.message);
          props.handelchange();
        } else {
          props.handleMessage("something went wrong");
          props.handelchange();
        }
        setOpenAddProduct(false);
      } else {
        handleAlert(
          "For Purchase a snapshot make Sure Your Card Must Be Registered!",
          "billing"
        );
      }
    };
    return (
      <MDModal open={open} onClose={onClose} className={"buy-modal"}>
        <div>
          <form className="form">
            <div className="banner"></div>
            <label className="title">
              {props.data.type == "snapshot"
                ? "Get Your Service Loaded"
                : "Get Your Snapshot Loaded"}
            </label>
            <label className="title-main">{props.data.name}</label>

            <div className="benefits">
              <span>Description</span>
              <ul>
                <li>
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 16"
                  height="16"
                  width="16"
                >
                  <rect fill="black" rx="8" height="16" width="16"></rect>
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="1.5"
                    stroke="white"
                    d="M5 8.5L7.5 10.5L11 6"
                  ></path>
                </svg> */}
                  <span>{props.data.description}</span>
                </li>
              </ul>
            </div>

            <div className="modal--footer">
              <label className="price">
                <sup>{location_settings.currency_sign}</sup>
                {props.data.payable == "no"
                  ? "Free"
                  : props.data?.purchase_listener}
              </label>
              <button
                className="upgrade-btn"
                type="button"
                onClick={handleSubmit}
              >
                Confirm to Buy
              </button>
            </div>
          </form>
        </div>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        onClick={handlemodal}
        sx={{ px: 2, py: 1, width: "60%" }}
        fullwidth
      >
        Buy {props.data.type} ({props.data.name})
      </MDButton>

      <AddProduct
        open={openAddProduct}
        handleAlert={props.handleAlert}
        onClose={closeAddProduct}
      />
    </>
  );
};

export default BuyModal;
