import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import localforage from "localforage";
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import SubscriptionModal from "./components/SubscriptionModal";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal";
import ReSubscriptionModal from "./components/ReSubscriptionModal";
import CreateSubscription from "./components/CreateSubscription";
import PurchaseModal from "./components/PurchasesDetailsModal";
import MDButton from "components/MDButton";
import { useAgencyInfo } from "context/agency";
function Default() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [processing, setprocessing] = useState(true);
  const [Conjo, setConjo] = useState([]);
  const [sessionkey, setsessionkey] = useState();
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);
  var locationId = decodeURIComponent(pathname.split("/")[3]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const location_settings = useAgencyInfo()[5];

  const getApps = async (e) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.Purchase_snaphot.getsubscriptions({
      query: `locationId=${loc_data.location_id}& agency_id=${loc_data.agency_id}`,
    });
    console.log(response, "responseresponseresponse");
    if (response) {
      const sessionKey = await localforage.getItem("sessionkey");
      var all_app_subs = response.data.filter(function (item) {
        return item.purchase_type == "all_app_subs";
      });
      console.log(all_app_subs, "all_app_subsall_app_subs");
      if (all_app_subs[0]) {
        var temp_data = all_app_subs[0];
        temp_data["name"] = "All Apps";
        temp_data["type"] = "all_apps";
        var data = [];
        var newArray = response.data.filter(function (item) {
          return item.purchase_type != "all_app_subs";
        });
        newArray.push(temp_data);
        data = newArray;
        await makeData(data, sessionKey);
      } else {
        await makeData(response.data, sessionKey);
      }
      setprocessing(false);
    } else {
      setprocessing(false);
    }
  };
  const makeData = async (data, sessionKey) => {
    // alert(location_settings.currency_sign);
    const tabel_data = [];
    data?.forEach((element) => {
      var temp = [
        element.name,
        element.type,
        element.sub_id,
        element.status,
        element.invoice?.total
          ? ` ${location_settings.currency_sign} ${
              element.invoice?.total / 100
            }`
          : null,
        <>
          {element.status == "incomplete" ? (
            <SubscriptionModal data={element} handleRefresh={onLoad} />
          ) : element.status == "cancel" ? (
            <ReSubscriptionModal data={element} handleRefresh={onLoad} />
          ) : element.status == "free_trial" ? (
            <CreateSubscription data={element} handleRefresh={onLoad} />
          ) : (
            <CancelSubscriptionModal data={element} handleRefresh={onLoad} />
          )}
        </>,
        <PurchaseModal data={element} />,
      ];
      tabel_data.push(temp);
    });
    setConjo(tabel_data);
  };
  const onLoad = async () => {
    // const loc_data = await localforage.getItem("sessionkey");
    // setsessionkey(loc_data);
    // // alert(loc_data);
    getApps();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Type",
      options: {
        filter: false,
      },
    },

    {
      name: "Subcription Id",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
    {
      name: "Invoice",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  return (
    <>
      <DashboardLayout>
        {processing ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        )}
      </DashboardLayout>
    </>
  );
}

export default Default;
