import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import localforage from "localforage";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
// import BuyModal_iframe from '../components/BuyModal_iframe'
import env from "config";
import { useAppServices } from "hook/services";
import Loader from "examples/Loader";
function Iframs() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const AppService = useAppServices();
  const [app_data, setapp_data] = useState({});
  const [processing, setprocessing] = useState(true);
  console.log(state, "statestate");
  const params = useParams();
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const getPurchasedApp = async (app) => {
    const { response } = await AppService.purchased_apps.filter({
      query: `app_id=${app?.app_id}`,
    });
    console.log(response, "getPurchasedApp");
    if (response) {
      if (
        response.data[0].preview_link == "" ||
        response.data[0].preview_link == undefined
      ) {
        // alert("found");
        setapp_data(app);
        setprocessing(false);
      } else {
        // alert(response.data[0].preview_link);

        setapp_data(response.data[0]);
        setprocessing(false);
      }
    } else {
      setapp_data(app);
      setprocessing(false);
    }
  };
  const getApp = async () => {
    const { response } = await AppService.app.filter({
      query: `_id=${params.id}`,
    });
    console.log(response, "getApp");
    if (response) {
      getPurchasedApp(response.data[0]);
    } else {
      setprocessing(false);
    }
  };

  useEffect(async () => {
    getApp();
  }, []);
  return (
    <DashboardLayout>
      {processing ? (
        <Loader />
      ) : (
        <>
          <Grid justifyContent={"start"} pb={2}>
            <MDButton onClick={() => navigate(-1)}>Go Back</MDButton>
          </Grid>
          <iframe
            style={iframeStyles}
            src={app_data?.preview_link || "https://app.snapshotstore.io"}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default Iframs;
