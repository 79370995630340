import { Environment } from "../enums";

const env = {
  // API_URL : 'https://dev.walletcampaigns.com/api',
  // API_URL : 'http://35.176.217.108:8080/api'
  // https://api.ghl.store
  // https://api.ghl.store
  API_URL: "http://localhost:8082/api",
  GHL: {
    CLIENT_ID: "6411a77d7aa422866c12a25b-lf9l3okq",
    CLIENT_SECRET: "dd810009-96d0-4618-8371-6db3cb380ff1",
    Location_CLIENT_ID: "644fb55e7f3e43716935b144-lh4uok72",
    Location_CLIENT_SECRET: "65bd9d21-0335-4e96-8ba0-dae7bc624914",
    Location_SCOPE:
      "contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly medias.write medias.readonly",
    SCOPE:
      "contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly medias.write medias.readonly",
    REDIRECT: {
      LOCATION: "https://app.levelupmarketplace.io/integrations/ghl/associate",
      BUSINESS: "https://app.levelupmarketplace.io/integrations/ghl/business",
      AGENCY: "https://app.levelupmarketplace.io/integrations/ghl/agency",
      SUPER_ADMIN: "https://app.snapshotstore.io/integrations/ghl/sadmin",
      Location_ASSOCIATE: "https://app.snapshotstore.io/agency/auth",
      SuperAdmin_ASSOCIATE: "https://app.snapshotstore.io/superadmin/auth",
      ASSOCIATE: "https://app.snapshotstore.io/agency/auth/agency",
    },
  },
  Webinar: {
    CLIENT_ID: "cb5d62f1-ddca-4dac-88a6-642318a4260a",
    CLIENT_SECRET: "l34ujqnCe9YWv9uNieZkEzvH",
    SCOPE:
      "identity:scim.me collab: support: identity:scim.org identity: call-control.v1.calls.control call-events.v1.events.read call-events.v1.notifications.manage call-history.v1.notifications.manage calls.v2.initiate cr.v1.read fax.v1.notifications.manage fax.v1.read fax.v1.write messaging.v1.notifications.manage messaging.v1.read messaging.v1.send messaging.v1.write presence.v1.notifications.manage presence.v1.read presence.v1.write recording.v1.notifications.manage recording.v1.read users.v1.lines.read voice-admin.v1.read voice-admin.v1.write voicemail.v1.notifications.manage voicemail.v1.voicemails.read voicemail.v1.voicemails.write webrtc.v1.read webrtc.v1.write",
    REDIRECT: {
      auth: "http://localhost:3001/integrations/webinar",
    },
  },
};

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = "https://api.ghl.store/api";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = "https://api.ghl.store/api";
}

export default env;
