import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppServices } from "hook/services";
import { useUserInfo } from "context/user";
import { useAgencyInfo } from "context/agency";
import "../../style.css";
import localforage from "localforage";

function AddProduct({ open, onClose,data,purchased_data ,handleRefresh}) {
  // alert(data.integration_type)
  const [processing, setProcessing] = useState(false);
  const { pathname } = useLocation();
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  const [user] = useUserInfo();
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  console.log(data,'data')
  const handleSubmit = async (e) => {
    e.preventDefault();

    setProcessing(true);
    var app_configuration={}
    if(data.integration_type=='basic_auth'){
      app_configuration = {
        email: e.target?.email.value,
        password: e.target?.password.value,
      };
    }else if(data.integration_type=='dual_api_keys'){
      app_configuration = {
        client_secert: e.target?.client_secert.value,
        client_id: e.target?.client_id.value,
      };
    }else{
      app_configuration = {
        api_key: e.target?.api_key.value,
      };
    }
    
    const loc_data = await localforage.getItem("loc_data");
    const payload = {
      app_id : data.app_id,
      locationId:loc_data.location_id,
      type:"freetest",
      app_configuration: app_configuration,
    };
    console.log(payload);
    const { response } = await AppService.location_apps.update({ payload });
    if(response){
      handleRefresh(true)
      onClose(true)
    }
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      <MDBox
        sx={{ width: 380, padding: "1rem" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <MDTypography mb={2} variant="h5">
          Configure Modal
        </MDTypography>
        {
          data.integration_type=='basic_auth' ?
          <>
          
          <MDBox>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            <b>Email:</b>
          </MDTypography>
          <input type="text" name="email" className="form-control" defaultValue={purchased_data?.app_configuration?.email} />
          </MDBox>
          <MDBox>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Password:</b>
        </MDTypography>
        <input type="password" name="password" className="form-control" defaultValue={purchased_data?.app_configuration?.password} />
        </MDBox>
          </>
          :
          data.integration_type=='dual_api_keys' ?
          <>
          
          <MDBox>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            <b>Sid:</b>
          </MDTypography>
          <input type="text" name="client_id" className="form-control" defaultValue={purchased_data?.app_configuration?.client_id} />
          </MDBox>
          <MDBox>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Secert Token:</b>
        </MDTypography>
        <input type="text" name="client_secert" className="form-control" defaultValue={purchased_data?.app_configuration?.client_secert} />
        </MDBox>
          </>
          :
          <MDBox>
          <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
            <b>App Key:</b>
          </MDTypography>
          <input type="text" name="api_key" className="form-control" defaultValue={purchased_data?.app_configuration?.api_key} />
          </MDBox>
        }
       
        <MDBox className="d-flex justify-content-end">
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            sx={{ mt: 4, mb: 1, ml: 1 }}
          >
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}
const Default = ({data,handleRefresh,purchased_data}) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color={"info"}
        size="small"
        onClick={() => setOpenAddProduct(true)}
      >
        Configure
      </MDButton>
      <AddProduct open={openAddProduct} handleRefresh={handleRefresh} purchased_data={purchased_data} data={data} onClose={closeAddProduct} />
    </>
  );
};

export default Default;
