import ListIcon from '@mui/icons-material/List';
import AppsIcon from '@mui/icons-material/Apps';
import { Grid } from '@mui/material'
import React, {  useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import localforage from 'localforage';
import Filter from '../components/Filter/Filter';
import { useAgencyInfo } from 'context/agency';
const ThemeNavbar = (props) => {
  const [agency]=useAgencyInfo()
    const [price, setprice] = useState('');
    const [GridView, setGridView] = useState(agency?.Customization?.FeaturesButton?.GridView)
    const [filter, setfilter] = useState(agency?.Customization?.FeaturesButton?.Filter)


  useEffect(async() => {

    const localagency = await localforage.getItem('agency_data');
    // console.log(localagency,'localagencylocalagencylocalagencylocalagency')
    // setfilter(localagency?.Customization?.FeaturesButton?.Filter)
    // setGridView(localagency?.Customization?.FeaturesButton?.GridView)
  }, [])

    const handleChange = (event) => {
      setprice(event.target.value);
    };
    const handleMessage =  (message)=>{
        props.handleview(message)
      }
  return (
    <>
    
 <Grid sx={{marginTop:'1px'}}>
  {filter==true ?
  (

<Filter handlerange={props.handlerange} handelchange={props.handelchange}/>
  )
:
(
  <></>
)}
 {GridView==true ?
  (
    <>

<AppsIcon fontSize='medium' sx={{cursor:'pointer',marginRight:'8px'}} onClick={()=>handleMessage('grid')}/>
<ListIcon fontSize='medium' sx={{marginRight:'8px',cursor:'pointer', width:"1.2em",height:'1.2em'}} onClick={()=>handleMessage('list')}/>
</>
  )
:
(
  <></>
)}
  
 </Grid>
   
    </>
  )
}

export default ThemeNavbar