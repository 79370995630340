import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import localforage from "localforage";
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import MDButton from "components/MDButton";
import PurchasesDetailsModal from "./components/PurchasesDetailsModal";
import IntegrationModal from "./components/IntegrationModal";
import env from "config";
import { RemoveRedEye } from "@mui/icons-material";
import ConfirmAlert from "./components/ConfirmAlert";
import { useAgencyInfo } from "context/agency";

function Default() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [processing, setprocessing] = useState(true);
  const [Conjo, setConjo] = useState([]);
  const [sessionkey, setsessionkey] = useState();
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);
  var locationId = decodeURIComponent(pathname.split("/")[3]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const location_settings = useAgencyInfo()[5];

  const getApps = async (e) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.Purchase_snaphot.getSnapshots({
      query: `loc_id=${loc_data.location_id}& agency_id=${loc_data.agency_id}`,
    });
    if (response) {
      const sessionKey = await localforage.getItem("sessionkey");
      await makeData(response.data, sessionKey);
      setprocessing(false);
    } else {
      setprocessing(false);
    }
  };
  const makeData = async (data, sessionKey) => {
    const tabel_data = [];
    data?.forEach((element) => {
      var type = element.purchase_prod.type;
      if (element.purchase_prod.type == "reselling_service") {
        type = "service";
      }
      var temp = [
        element.prod.name,
        type,
        location_settings.currency_sign + element.purchase_prod.price || "0",
        element.user_data.email,
        element.purchase_prod.createdAt,
        <MDButton
          sx={{ marginLeft: "1rem" }}
          variant="contained"
          color={"info"}
          size="small"
          onClick={() =>
            navigate(
              `/store/${type}/${locationId}/${loc_name}/${loc_add}/agency/${agency_id}/snapshot/${element.prod._id}/SnapshotDetail`
            )
          }
        >
          Documentation
        </MDButton>,
      ];
      if (element.purchase_prod.type != "reselling_bundle") {
        tabel_data.push(temp);
      }
    });
    setConjo(tabel_data);
  };
  const onLoad = async () => {
    // const loc_data = await localforage.getItem("sessionkey");
    // setsessionkey(loc_data);
    // // alert(loc_data);
    getApps();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "createdAt",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Invoice",
    //   options: {
    //     filter: false,
    //   },
    // },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  return (
    <>
      <DashboardLayout>
        {processing ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        )}
      </DashboardLayout>
    </>
  );
}

export default Default;
