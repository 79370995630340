/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Routes, Route, Navigate, useParams } from "react-router-dom";

// Material Dashboard 2 React routes
import routes from "./routes";
import { useAgencyInfo } from "context/agency";
import Sidenav from "layouts/theme1/Sidenav";
import { setMiniSidenav, useMaterialUIController } from "context";
import { useState } from "react";
import localforage from "localforage";
export default function Integration() {
  const [agency] = useAgencyInfo();
  const searchParams = new URLSearchParams(document.location.search);

  const [controller, dispatch] = useMaterialUIController();
  const params = useParams();
  var type = "";
  const location_id = params.location_id;
  // alert(location_id);
  const agency_id = params.agency_id;
  var loc_name = params.loc_name;
  var loc_add = params.loc_add;
  const sessionkey = searchParams.get("sessionkey");
  if (sessionkey != null) {
    localforage.setItem("sessionkey", sessionkey);
  }
  const loc_data = {
    location_id: location_id,
    agency_id: agency_id,
    loc_name: loc_name,
    loc_add: loc_add,
    agency_id: agency_id,
  };
  localforage.setItem("loc_data", loc_data);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });
  const {
    miniSidenav,
    direction,
    openConfigurator,
    // sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      {agency?.Customization?.navbar?.Categries ? (
        <></>
      ) : (
        <Sidenav
          // color={sidenavColor}
          // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Navigate to="/auth" />} /> */}
      </Routes>
    </>
  );
}
