/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import {
  Link,
  useLocation,
  useNavigate,
  // useNavigate
} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from '@mui/material/Switch'
import Grid from "@mui/material/Grid";
// import MuiLink from '@mui/material/Link'

// // @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook'
// import GitHubIcon from '@mui/icons-material/GitHub'
// import GoogleIcon from '@mui/icons-material/Google'
import FoegetPassword from "./components/FoegetPassword";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useFormik } from "formik";
import { AuthVld } from "validation";
import { useAppServices } from "hook/services";
import { useUserInfo } from "context/user";
import localforage from "localforage";
import { useBrandInfo } from "context/brand";
import { useAgencyInfo } from "context/agency";
import env from "config";
// import { Button } from '@mui/material'
const BASE_URL = `${env.API_URL}/v1`;

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false)

  // const handleSetRememberMe = () => setRememberMe(!rememberMe)
  const Service = useAppServices();
  const updateBrand = useBrandInfo()[1];
  const updateUser = useUserInfo()[1];
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "statestatestate");
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState();
  const [error, seterror] = useState();

  const initState = {
    email: "",
    password: "",
  };
  localforage.setItem("myapps_data", state);

  const updateAgency = useAgencyInfo()[3];
  const handleSubmit = async (e) => {
    e.preventDefault();
    var axios = require("axios");

    const email = e.target.email.value;
    const password = e.target.password.value;
    var loc_name = state.loc_name;
    var loc_add = state.loc_add;
    var location_id = state.location_id;

    const data = {
      email: email,
      password: password,
      location_id: location_id,
    };
    console.log(data, "data");
    axios
      .post(BASE_URL + "/auth/userlogin", { data })
      .then(async function (response) {
        if (response.data.success == true) {
          localforage.setItem("brand", response.data.data);
          updateUser(response.data.data);
          setTimeout(() => {
            navigate(
              `/apps/myapps/${state.location_id}/${loc_name}/${loc_add}/agency/${state.agency_id}?${state.sessionkey}`
            );
          }, 2000);
        }
      })
      .catch(function (error) {
        seterror(error.response.data.message);
        setTimeout(() => {
          seterror("");
        }, 3000);
      });
  };

  const handlemessage = async (e, type) => {
    if (type == "true") {
      setMessage(e);
    } else {
      seterror(e);
    }
    setTimeout(() => {
      seterror("");
      setMessage("");
    }, 3000);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            {/* <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <FacebookIcon color="inherit" />
                </MDTypography>
              </Grid> */}
            {/* <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GitHubIcon color="inherit" />
                </MDTypography>
              </Grid> */}
            {/* <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GoogleIcon color="inherit" />
                </MDTypography>
              </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <FoegetPassword handlemessage={handlemessage} />
            <MDBox mt={3} mb={1}>
              <MDButton
                variant="gradient"
                type="submit"
                color="info"
                fullWidth
                className="defeult-theme-button "
              >
                Sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
