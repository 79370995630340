import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import BuyAllAppModal from "../BuyAllAppModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import localforage from "localforage";
import ConfirmAlert from "../ConfirmAlert";
import { useAppServices } from "hook/services";
import { useEffect } from "react";
function Iframs() {
  const navigate = useNavigate();
  const AppService = useAppServices();
  const { pathname } = useLocation();
  const [text, settext] = React.useState("");
  const [IframeUrl, setIframeUrl] = React.useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const getIframeUrl = async (e) => {
    const { response } = await AppService.app.getapp_setup();
    // console.log(response.data.preview_link, "response.data.preview_link");
    if (response) {
      setIframeUrl(response.data.preview_link);
    } else {
      setIframeUrl([]);
    }
  };
  const onLoad = () => {
    getIframeUrl();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  const handleAlert = async (text, alert_type) => {
    const localBrand = await localforage.getItem("loc_data");
    setState({ ...state, open: true });
    settext(text);
    // alert(alert_type)
    if (alert_type == "auth") {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/auth`
        );
      }, 2000);
    } else if (alert_type == "session key") {
      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/setting
s/payment`
        );
      }, 2000);
    }
  };
  return (
    <DashboardLayout>
      <ConfirmAlert handleSubmit={handleAlert} state={state} text={text} />
      <MDBox display="flex" justifyContent="center" mb={3}>
        <BuyAllAppModal />
      </MDBox>
      <iframe style={iframeStyles} src={IframeUrl} />
    </DashboardLayout>
  );
}

export default Iframs;
