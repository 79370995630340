/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// OrderDetails page components
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";

function OrderDetails({ data }) {
  return (
    <MDBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <MDBox pt={1} pb={3} px={3}>
              <MDBox mb={3}>
                {/* <Header data={data} /> */}
                <OrderInfo data={data} />
              </MDBox>
              <Divider />
              <MDBox mt={3}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <PaymentDetails data={data} />
                  </Grid>
                  <Grid item xs={6} sx={{ ml: "auto" }}>
                    <OrderSummary data={data} />
                  </Grid>
                  <Grid item xs={6}>
                    <BillingInformation data={data} />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OrderDetails;
