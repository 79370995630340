import { Card, Divider, Grid, IconButton, Switch } from '@mui/material'
import Header from './Components/Header'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../DashboardNavbar'
import LocationPurchases from './LocationPurchases'
import UserPurchases from './UserPurchases'
import { useEffect, useState } from 'react'
import localforage from 'localforage'
import { useAppServices } from 'hook/services'
function Purchases() {
  const AppService=useAppServices()
  const [purchased_apps, setpurchased_apps] = useState([]);
  const getAppconfiguration = async (apps_data) => {
    const loc_data = await localforage.getItem("loc_data");

    const { response } = await AppService.purchased_apps.filter({
      query: `agency_id=${loc_data.agency_id}`,
    });
    if (response) {
      setpurchased_apps(response.data)
    }
  };
  const onLoad=()=>{
    getAppconfiguration()
  }
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container mt={10}>
          <Grid item xs={12}>
            <Header elements={[<LocationPurchases purchased_apps={purchased_apps}/>,<UserPurchases purchased_apps={purchased_apps}/>]} />
          </Grid>
        </Grid>
    </DashboardLayout>
  )
}

export default Purchases