/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Authentication from "layouts/authentication";
// import Admin from "layouts/admin";
// import Snapshot from "layouts/snapshot";
// import Service from "layouts/service";
import Integration from "layouts/integration";
// import Custom from "layouts/custom";
import Theme1 from "layouts/theme1";
import MyApps from "layouts/MyApps";
// import Websites from "layouts/websites";
// import Funnels from "layouts/funnels";
// import Automation from "layouts/automation";
import { useUserInfo } from "context/user";
import { useBrandInfo } from "context/brand";
import NotFound from "layouts/not-found";
import FreeApps from "layouts/FreeApps";
import oauthComponent from "layouts/oauthComponent";
import Auth from "layouts/theme1/Auth";
import { Roles } from "enums/user";
import { useAgencyInfo } from "context/agency";
import { useLocation } from "react-router-dom";

const useRoutes = () => {
  const [user] = useUserInfo();
  const [brand] = useBrandInfo();
  const searchParams = new URLSearchParams(document.location.search);
  const [agency, Update, clear, getAgency, paramsData] = useAgencyInfo();
  const params = useAgencyInfo()[4];
  const sessionkey = searchParams.get("sessionkey");
  const { pathname } = useLocation();
  var page = decodeURIComponent(pathname.split("/")[1]);
  var type = decodeURIComponent(pathname.split("/")[2]);
  var location_id = decodeURIComponent(pathname.split("/")[3]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);

  return [
    {
      type: "collapse",
      key: "not_found",
      route: "/404",
      component: <NotFound />,
    },

    {
      type: "collapse",
      key: "test",
      route: "/test",
      component: <oauthComponent />,
    },
    {
      type: "collapse",
      key: "authentication",
      route: `/store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency_id}/auth?sessionkey=${sessionkey}&page=${page}`,
      component: <Auth />,
      guard: {
        valid: !user.email,
        redirect: `/apps/myapps/${paramsData?.location_id}/${paramsData?.loc_name}/${paramsData?.loc_add}/agency/${paramsData?.agency_id}?sessionkey=${sessionkey}`,
      },
    },
    {
      type: "collapse",
      key: "integration",
      route: "apps/myapps/:location_id/:loc_name/:loc_add/agency/:agency_id/*",
      component: <MyApps />,
      guard: {
        valid: user.email && user.roles.includes(Roles.ADMIN),
        redirect: user.email
          ? `/myapps/*`
          : `/store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency_id}/auth?sessionkey=${sessionkey}&page=${page}`,
      },
    },
    {
      type: "collapse",
      key: "integration",
      route: "/integrations/*",
      component: <Integration />,
    },
    {
      type: "collapse",
      key: "admin",
      route: "store/:type/:location_id/:loc_name/:loc_add/agency/:agency_id/*",
      component: <Theme1 />,
    },
    {
      type: "collapse",
      key: "admin",
      route: "agency/app/:location_id/:loc_name/:loc_add/agency/:agency_id/*",
      component: <FreeApps />,
    },
  ];
};

export default useRoutes;
