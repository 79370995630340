// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook'
// import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import ProfilesList from 'examples/Lists/ProfilesList'

import { useUserInfo } from "context/user";

import burceMars from "assets/images/bruce-mars.jpg";

// Overview page components
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Card, Icon } from "@mui/material";
import { useLogout } from "hook/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import localforage from "localforage";
import env from "config";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../DashboardNavbar";

function Profile() {
  let navigate = useNavigate();
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();

  const [user] = useUserInfo();
  const handleLogout = async () => {
    const localBrand = await localforage.getItem("loc_data");
    localforage.clear();
    var path = pathname.split("/")[1];
    var loc_name = pathname.split("/")[4].replace(/\s/g, "");
    var type = decodeURIComponent(pathname.split("/")[2]);
    var loc_add = pathname.split("/")[5].replace(/\s/g, "");

    navigate(
      `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/Snapshot`
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} mt={6}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox
            className="defeult-theme-card"
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Profile
            </MDTypography>
          </MDBox>
          <MDBox mt={4}>
            <Grid container spacing={3} alignItems="center">
              <Grid item ml="auto">
                <MDBox
                  onClick={handleLogout}
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                >
                  <Icon>logout</Icon>
                  <MDTypography
                    ml={1}
                    mr={5}
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Logout
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <ProfileInfoCard
                    title="profile information"
                    // description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                    info={{
                      fullName: user?.username,
                      mobile: user?.phone,
                      email: user?.email,
                    }}
                    social={[]}
                    // social={[
                    //   {
                    //     link  : 'https://www.facebook.com/CreativeTim/',
                    //     icon  : <FacebookIcon />,
                    //     color : 'facebook',
                    //   },
                    //   {
                    //     link  : 'https://twitter.com/creativetim',
                    //     icon  : <TwitterIcon />,
                    //     color : 'twitter',
                    //   },
                    //   {
                    //     link  : 'https://www.instagram.com/creativetimofficial/',
                    //     icon  : <InstagramIcon />,
                    //     color : 'instagram',
                    //   },
                    // ]}
                    // action={{ route: '', tooltip: 'Edit Profile' }}
                    action={{ route: "" }}
                    shadow={false}
                  />
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
