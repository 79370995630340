import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
import { useAppServices } from "hook/services";
import { user } from "enums";
import { useUserInfo } from "context/user";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAgencyInfo } from "context/agency";

function AddProduct({
  open,
  onClose,
  data,
  stripe_data,
  handlerefresh,
  handleAlert,
}) {
  const stripe = useStripe();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [processing, setProcessing] = useState(false);
  const location_settings = useAgencyInfo()[5];
  const { pathname } = useLocation();
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  const [user] = useUserInfo();
  const [agency] = useAgencyInfo();
  let navigate = useNavigate();
  const AppService = useAppServices();
  const handleSubmit = async (newState) => {
    // e.preventDefault();

    setProcessing(true);

    const brand = await localforage.getItem("brand");
    const loc_data = await localforage.getItem("loc_data");
    const app = data;
    const test = true;
    if (stripe_data == true) {
      const payload = {
        locationId: loc_data.location_id,
        user_id: brand._id,
        loc_name: loc_name,
        loc_add: loc_add,
        agency_id: agency._id,
      };
      const { response } = await AppService.location_apps.CreateAllAppPurchase({
        payload,
      });
      // return 0;
      if (response) {
        if (response.status == "completed") {
          setProcessing(false);
          onClose(false);
          handlerefresh();
        } else {
          stripe
            .confirmCardPayment(response.data.client_secret, {})
            .then(async function (result) {
              const status = "succeeded";
              // if (status == "succeeded") {
              if (result?.paymentIntent?.status == "succeeded") {
                const payload = {
                  config_app_data: response.agency_sub,
                  locationId: loc_data.location_id,
                  user_id: brand._id,
                  loc_name: loc_name,
                  loc_add: loc_add,
                  agency_id: agency._id,
                  sub_id: response.subscription_data.id,
                  status: "succeeded",
                };
                const update_result =
                  await AppService.location_apps.ConfirmAllAppPurchase({
                    payload,
                  });
                // return 0;
                if (update_result.response) {
                  setProcessing(false);
                  onClose(true);
                  handlerefresh();
                }
              } else {
                setProcessing(false);
                // alert("fyg");
                onClose(true);
                handlerefresh();
              }
            });
        }
      } else {
        setProcessing(false);
        onClose(true);
        // handlerefresh();
      }
    } else {
      setProcessing(false);
      onClose(true);

      handleAlert(
        "For Purchase a app make Sure Your Card Must Be Registered!",
        "billing"
      );
    }
    // const { response } = await AppService.stripe.addSubscription({ payload })

    // setProcessing(false)
    // onClose(response?.data)
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      {/* <MDBox sx={{ width: 365 }}>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Snapshot Name:</b> {props.data.name}
        </MDTypography>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Description:</b> {props.data.description}
        </MDTypography>
        <MDTypography variant="body1" sx={{ fontSize: "16px" }}>
          <b>Price:</b>{" "}
          {props.data.payable == "no"
            ? "Free"
            : "$" +
              props.data?.purchase_listener?.substr(
                0,
                props.data?.purchase_listener?.indexOf("(")
              )}
        </MDTypography>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          Your {props.data.type} will be loaded on location{" "}
          <b>({loc_name}) </b>
        </MDTypography>
        <MDBox component="form" role="form" sx={{ width: 365 }}>
          <MDBox className="d-flex justify-content-end">
            <MDButton
              variant="gradient"
              color="primary"
              type="button"
              sx={{ mt: 4, mb: 1 }}
              onClick={closeAddProduct}
            >
              close
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              sx={{ mt: 4, mb: 1, ml: 1 }}
              onClick={handleSubmit}
            >
              confirm {props.label}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox> */}
      <div>
        <form className="form">
          <div className="banner"></div>
          <label className="title">Get Access To All Apps In One Click</label>
          {/* <label className="title-main">{"props.data.name"}</label> */}

          <div className="benefits">
            <span>Description</span>
            <ul>
              <li>
                <span>
                  <b>Simplicity</b>: Say goodbye to juggling multiple
                  subscriptions, bills, and login credentials. Our single
                  subscription simplifies your digital life.
                </span>
              </li>
              <li>
                <span>
                  <b>Cost-Effective</b>: Enjoy substantial savings compared to
                  buying individual app subscriptions. Get more value for your
                  money.
                </span>
              </li>
              <li>
                <span>
                  <b>Variety</b>: From essential productivity apps to
                  specialized tools, our selection covers a broad spectrum of
                  applications, ensuring you have the right tool for every task.
                </span>
              </li>
              <li>
                <span>
                  <b>Continuous Updates</b>: Your subscription keeps you
                  up-to-date with the latest features and improvements across
                  all our apps.
                </span>
              </li>
              <li>
                <span>
                  <b>User-Friendly</b>: Our platform is user-friendly, making it
                  easy to navigate and explore the full range of applications
                  available.
                </span>
              </li>
              <li>
                <span>
                  Unlock a World of Possibilities with a Single Subscription!
                </span>
              </li>
            </ul>
          </div>

          <div class="modal--footer">
            <label class="price">
              {/* <sup>{location_settings.currency_sign}</sup>
              {props.data.payable == "no"
                ? "Free"
                : props.data?.purchase_listener} */}
            </label>
            <MDButton
              loading={processing}
              disabled={processing}
              class="upgrade-btn"
              type="button"
              onClick={handleSubmit}
            >
              Confirm to Buy
            </MDButton>
          </div>
        </form>
      </div>
    </MDModal>
  );
}
const BuyModal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  const [stripe_data, setstripe_data] = useState(false);
  const stripePromise = loadStripe(agency.stripe.publish_key);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();

  const inputRef = useRef();
  var path = pathname.split("/")[2];
  var loc_name = pathname.split("/")[5].replace(/\s/g, "");
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = pathname.split("/")[6].replace(/\s/g, "");
  var axios = require("axios");
  let navigate = useNavigate();

  const handlemodal = async (newState) => {
    if (brand == null) {
      const localBrand = await localforage.getItem("loc_data");
      props.handleAlert(
        "for purchase a app make sure you must be login!",
        "auth"
      );
    } else {
      setOpenAddProduct(true);
    }
  };
  const getStripe = async (e) => {
    const user_data = await localforage.getItem("brand");
    const { response } = await AppService.stripe.filter({
      id: user_data._id,
    });
    if (response) {
      setstripe_data(true);
    } else {
      setstripe_data(false);
    }
  };
  useEffect(async () => {
    getStripe();
  }, []);

  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        size="medium"
        color="primary"
        className="purchase-btn"
        onClick={handlemodal}
      >
        {"Get Access To All Apps"}
      </MDButton>
      <Elements stripe={stripePromise}>
        <AddProduct
          open={openAddProduct}
          stripe_data={stripe_data}
          handlerefresh={props.handlerefresh}
          onClose={closeAddProduct}
          handleAlert={props.handleAlert}
        />
      </Elements>
    </>
  );
};

export default BuyModal;
