import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { RemoveRedEye } from "@mui/icons-material";
import PurchasesDetails from "../purchases_details";
const Default = ({ data }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  function AddProduct({ open, onClose, data }) {
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={"auto"}
        height={"80vh"}
        overflow={"auto"}
      >
        <PurchasesDetails data={data} />
      </MDModal>
    );
  }
  const closeAddProduct = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <RemoveRedEye
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenAddProduct(true)}
      />

      <AddProduct open={openAddProduct} onClose={closeAddProduct} data={data} />
    </>
  );
};

export default Default;
