/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import { useBrandInfo } from "context/brand";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "./ComplexStatisticsCard";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./DefaultProjectCard";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import React, { useState, useEffect } from "react";
import localforage from "localforage";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useAppServices } from "hook/services";
function Category({
  color,
  title,
  count,
  percentage,
  icon,
  data,
  category_data,
  handleAlert,
  agency_data,
  cat_id,
  handelchange,
  location_data,
  handleGrid,
  purchased_apps,
}) {
  const [brand] = useBrandInfo();
  const loc_data = localforage.getItem("loc_data");
  const [message, setmessage] = useState("");

  const [stripe_data, setstripe_data] = useState(false);
  const AppService = useAppServices();
  const getStripe = async (e) => {
    const user_data = await localforage.getItem("brand");
    const { response } = await AppService.stripe.filter({
      id: user_data._id,
    });
    if (response) {
      setstripe_data(true);
    } else {
      setstripe_data(false);
    }
  };
  useEffect(async () => {
    getStripe();
  }, []);
  const handleMessage = (message) => {
    // alert(message)
    setmessage(message);
    setTimeout(() => {
      setmessage("");
    }, 4000);
  };
  return (
    <>
      {message != "" ? (
        <Stack sx={{ width: "100%" }} spacing={2} mb={2} mt={0}>
          <Alert severity="success">{message}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      {category_data.map((category, key) =>
        cat_id == "" ? (
          <ComplexStatisticsCard
            handleAlert={handleAlert}
            category={category}
            data={data}
            stripe_data={stripe_data}
            cat_id={cat_id}
            handelchange={handelchange}
            location_data={location_data}
            purchased_apps={purchased_apps}
            handleMessage={handleMessage}
            handleGrid={handleGrid}
          />
        ) : category._id == cat_id ? (
          <ComplexStatisticsCard
            handleAlert={handleAlert}
            stripe_data={stripe_data}
            category={category}
            data={data}
            handleGrid={handleGrid}
            cat_id={cat_id}
            location_data={location_data}
            purchased_apps={purchased_apps}
            handelchange={handelchange}
            handleMessage={handleMessage}
          />
        ) : (
          <></>
        )
      )}
    </>
  );
}

// Setting default values for the props of Category
Category.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the Category
Category.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    handleGrid: PropTypes.string,
    data: PropTypes.array,
    category_data: PropTypes.array,
    agency_data: PropTypes.array,
    location_data: PropTypes.array,
    cat_id: PropTypes.string,
    handelchange: PropTypes.func,
  }),
  icon: PropTypes.node.isRequired,
};

export default Category;
