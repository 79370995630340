import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
import { useAppServices } from "hook/services";
import ConfirmAlert from "./ConfirmAlert";
import { user } from "enums";
import { useUserInfo } from "context/user";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAgencyInfo } from "context/agency";
import "../../Snapshot/components/style.css";

function AddProduct({
  open,
  onClose,
  data,
  stripe_data,
  handlerefresh,
  handleAlert,
}) {
  const stripe = useStripe();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [processing, setProcessing] = useState(false);
  const location_settings = useAgencyInfo()[5];
  const { pathname } = useLocation();
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  const [user] = useUserInfo();
  const [agency] = useAgencyInfo();
  let navigate = useNavigate();
  const AppService = useAppServices();
  const handleSubmit = async (newState) => {
    setProcessing(true);

    const brand = await localforage.getItem("brand");
    const loc_data = await localforage.getItem("loc_data");
    const app = data;
    const test = true;
    if (stripe_data == true) {
      const payload = {
        app_id: data.app_id,
        locationId: loc_data.location_id,
        user_id: brand._id,
        loc_name: loc_name,
        loc_add: loc_add,
        agency_id: agency._id,
      };
      const { response } = await AppService.location_apps.create({ payload });
      if (response) {
        if (response.status == "completed") {
          setProcessing(false);
          onClose(false);
          handlerefresh();
        } else {
          stripe
            .confirmCardPayment(response.data.client_secret, {})
            .then(async function (result) {
              const status = "succeeded";
              if (result?.paymentIntent?.status == "succeeded") {
                const payload = {
                  create_status: true,
                  app_id: data.app_id,
                  config_app_data: response.agency_sub,
                  locationId: loc_data.location_id,
                  user_id: brand._id,
                  loc_name: loc_name,
                  loc_add: loc_add,
                  agency_id: agency._id,
                  sub_id: response.subscription_data.id,
                  status: "succeeded",
                };
                const update_result = await AppService.location_apps.update({
                  payload,
                });
                if (update_result.response) {
                  setProcessing(false);
                  onClose(true);
                  handlerefresh();
                }
              } else {
                setProcessing(false);
                // alert("fyg");
                onClose(true);
                handlerefresh();
              }
            });
        }
      } else {
        setProcessing(false);
        onClose(true);
        // handlerefresh();
      }
    } else {
      setProcessing(false);
      onClose(true);

      handleAlert(
        "For Purchase a app make Sure Your Card Must Be Registered!",
        "billing"
      );
    }
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      {/* <MDBox sx={{ width: 365 }}>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>App Name:</b> {data.name}
        </MDTypography>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Description:</b> {data.description}
        </MDTypography>
        <MDTypography variant="body1" sx={{ fontSize: "16px" }}>
          <b>Price:</b>{" "}
          {"$" +
            data?.selling_price?.value.substr(
              0,
              data?.selling_price?.value.indexOf(" ")
            )}
        </MDTypography>
        <MDBox component="form" role="form" sx={{ width: 365 }}>
          <MDBox className="d-flex justify-content-end" mr={2}>
            <MDButton
              variant="gradient"
              color="primary"
              type="button"
              sx={{ mt: 4, mb: 1 }}
              onClick={onclose}
            >
              close
            </MDButton>
            {/* <ConfirmAlert
              handleSubmit={handleSubmit}
              state={state}
              color={"info"}
              text={
                "For Purchase a Snapshot make Sure Your Card Must Be Registered!"
              }
              variant={"gradient"}
              buttonLabel={"confirm Buy"}
            /> */}
      {/* <MDButton
              onClick={handleSubmit}
              variant="gradient"
              color="info"
              sx={{ mt: 4, mb: 1, ml: 1 }}
            >
              confirm Buy
            </MDButton> */}
      {/* </MDBox> */}
      {/* // </MDBox> */}
      {/* </MDBox> */}
      <div>
        <form className="form">
          <div className="banner"></div>
          <label className="title">Get Your App Loaded</label>
          <label className="title-main">{data.name}</label>

          <div className="benefits">
            <span>Description</span>
            <ul>
              <li>
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 16"
                    height="16"
                    width="16"
                  >
                    <rect fill="black" rx="8" height="16" width="16"></rect>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      stroke="white"
                      d="M5 8.5L7.5 10.5L11 6"
                    ></path>
                  </svg> */}
                <span> {data.description}</span>
              </li>
            </ul>
          </div>

          <div className="modal--footer">
            <label className="price">
              <sup>{location_settings.currency_sign}</sup>
              {data?.configure ? (
                <>
                  {data.selling_price?.value.substr(
                    0,
                    data.selling_price?.value.indexOf(" ")
                  )}
                </>
              ) : (
                <>
                  {data.basic?.value.substr(0, data.basic?.value.indexOf(" ")) *
                    3}
                </>
              )}
            </label>
            <MDButton
              loading={processing}
              disabled={processing}
              className="upgrade-btn"
              type="button"
              onClick={handleSubmit}
            >
              Confirm to Buy
            </MDButton>
          </div>
        </form>
      </div>
    </MDModal>
  );
}
const BuyModal = (props) => {
  const AppService = useAppServices();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [agency] = useAgencyInfo();
  const stripePromise = loadStripe(agency.stripe.publish_key);
  const { pathname } = useLocation();

  const handlemodal = async (newState) => {
    if (brand == null) {
      props.handleAlert(
        "for purchase a app make sure you must be login!",
        "auth"
      );
    } else {
      setOpenAddProduct(true);
      // console.log(props, "props.data");
    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    // alert(brand_data)
    setbrand(brand_data);
  }, []);
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        size="small"
        color="primary"
        className="purchase-btn"
        onClick={handlemodal}
      >
        {props.label}
      </MDButton>
      {openAddProduct == true ? (
        <Elements stripe={stripePromise}>
          <AddProduct
            stripe_data={props.stripe_data}
            handleAlert={props.handleAlert}
            handlerefresh={props.handlerefresh}
            open={openAddProduct}
            onClose={closeAddProduct}
            data={props.data}
          />
        </Elements>
      ) : (
        <MDBox />
      )}
    </>
  );
};

export default BuyModal;
