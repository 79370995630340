import axios from "axios";
import env from "config";
import { setNotification, useNotifcation } from "context/notification";
import { ReqMethods, ResponseStatus } from "enums";
import { useAuth, useLogout } from "./auth";

const BASE_URL = `${env.API_URL}/v1`;
const BASE_URL_V2 = `${env.API_URL}/v2`;

function useServiceHandler() {
  const [, dispatch] = useNotifcation();
  const Logout = useLogout();

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        console.log("API - RESPONSE", res, toaster, message, error);
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: "Success",
          });

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          setNotification(dispatch, {
            open: true,
            message: error || "session expired",
            title: "Error",
            severity: "error",
          });
          setTimeout(Logout, 4000);
          return { error: err.response?.data || err };
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message;

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: "error",
            title: "Error",
          });
        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = useAuth();
  const serviceHandler = useServiceHandler();

  const CallService = (
    method,
    path,
    { query, payload, token = true, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token)
      config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */
  const APIs = {
    auth: {
      login: (options) =>
        CallService(POST, `${BASE_URL}/auth/userlogin`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
      getuserpurchases: (options) =>
        CallService(GET, `${BASE_URL}/user/agency`, options),
    },
    categories: {
      get: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/category/getsnaphotcategories`,
          options
        ),
    },
    app: {
      get: (options) => CallService(GET, `${BASE_URL}/app`, options),
      getapp_setup: (options) =>
        CallService(GET, `${BASE_URL}/app/getapp_setup`, options),
      getPurchasedBundle: (options) =>
        CallService(GET, `${BASE_URL}/app/getPurchasedBundle`, options),
      getfilterapps: (options) =>
        CallService(GET, `${BASE_URL}/app/getfilterapps`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/app`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/app/filter`, options),
    },
    vote: {
      get: (options) => CallService(GET, `${BASE_URL}/vote`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/vote`, options),
      create: (options) => CallService(POST, `${BASE_URL}/vote`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/vote`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/vote/filter`, options),
    },
    snapshot: {
      load_snapshot: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/create_snapshot/load/${options.user_id}/${options.agency_id}/${options.location_id}`,
          options
        ),
      loadoffer: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/create_snapshot/loadoffer`,
          options
        ),
        
        loadreselloffer: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/create_snapshot/loadreselloffer`,
          options
        ),
    },
    purchased_apps: {
      get: (options) => CallService(GET, `${BASE_URL}/purchasedapp`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/purchasedapp`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/purchasedapp`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/purchasedapp`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/purchasedapp/filter`, options),
    },
    appconnection: {
      get: (options) => CallService(GET, `${BASE_URL}/appconnection`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/appconnection`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/appconnection`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/appconnection`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/appconnection/filter`, options),
    },
    location_apps: {
      get: (options) => CallService(GET, `${BASE_URL}/locationapp`, options),
      getapikey: (options) =>
        CallService(POST, `${BASE_URL}/locationapp/getkey`, options),
      getfreeapps: (options) =>
        CallService(GET, `${BASE_URL}/locationapp/free_apps`, options),
      getLocsnapshots: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getLocsnapshots`,
          options
        ),
      getmyapps: (options) =>
        CallService(GET, `${BASE_URL}/locationapp/getmyapps`, options),
      confirmsubscription: (options) =>
        CallService(
          POST,
          `${BASE_URL}/locationapp/confirmsubscription`,
          options
        ),
      cancelsubscription: (options) =>
        CallService(
          POST,
          `${BASE_URL}/locationapp/cancelsubscription`,
          options
        ),
      resubscription: (options) =>
        CallService(POST, `${BASE_URL}/locationapp/resubscription`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/locationapp`, options),
      freeupdate: (options) =>
        CallService(PUT, `${BASE_URL}/locationapp/freeupdate`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/locationapp`, options),

      CreateAllAppPurchase: (options) =>
        CallService(POST, `${BASE_URL}/locationapp/AllAppPurchase`, options),
      ConfirmAllAppPurchase: (options) =>
        CallService(PUT, `${BASE_URL}/locationapp/AllAppPurchase`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/locationapp`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/locationapp/filter`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      superadmin: (options) =>
        CallService(PUT, `${BASE_URL}/brand/superadmin`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      location_settings: (options) =>
        CallService(
          GET,
          `${BASE_URL_V2}/snapshot/agency/location_settings/filter`,
          options
        ),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      getProducts: (options) =>
        CallService(PUT, `${BASE_URL}/agency/getproducts`, options),
    },
    superadmin: {
      getProducts: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/superadmin_settings/workflows`,
          options
        ),
    },
    utils: {
      upload_image: (options) =>
        CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    Purchase_snaphot: {
      getlocations: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/purchaseitems`,
          options
        ),
      getpurchases: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/location_purchases`,
          options
        ),
      getSnapshots: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/getsnapshots`,
          options
        ),
      getsubscriptions: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/getsubscriptions`,
          options
        ),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/filter`,
          options
        ),
    },
    stripe: {
      // product: (options) => CallService(GET, `${BASE_URL}/services/stripe/products`, options),
      // getCustomer: (options) => CallService(GET, `${BASE_URL}/services/stripe/customers`, options),
      integrateAcocunt: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/agency/stripe/integrate`,
          options
        ),
      invoices: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/stripe/invoices/${options.agency_id}/${options.user_id}`,
          options
        ),
      update: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/stripe/update/${options.id}`,
          options
        ),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/stripe/submit`, options),
      delete: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/stripe/delete/${options.id}`,
          options
        ),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/stripe/filter/${options.id}`,
          options
        ),
    },
    services: {
      ghl: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
      webinar: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/webinar/`, options),
      },
      zoom: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/zoom/`, options),
      },
    },
  };

  return APIs;
}
const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    form.append("image", file, file.name);
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    });
  };
};
export { useAppServices, useCallService, useUploadImage };
