/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import localforage from "localforage";
// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "./styles/sidenavCollapse";
// @mui material components
import ListItem from "@mui/material/ListItem";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import "./style.css";
import "./ghl.css";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
// Material Dashboard 2 React components
import DoneIcon from "@mui/icons-material/Done";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";

function Sidenav({ color, brand, brandName, level, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const AppService=useAppServices()
  const [agency] = useAgencyInfo();
  const navigate = useNavigate();
  const collapseName = location.pathname.split("/").pop();
  const parent_path = location.pathname.split("/")[8];
  var loc_name = location.pathname.split("/")[4].replace(/\s/g, "");
  var type = decodeURIComponent(location.pathname.split("/")[2]);
  var location_id = decodeURIComponent(location.pathname.split("/")[3]);
  var loc_add = location.pathname.split("/")[5].replace(/\s/g, "");

  const [open, setOpen] = React.useState(false);
  const [allapps, setallapps] = React.useState(false);
  const [allappsloader, setallappsloader] = React.useState(true);
  const [user_data, setuser_data] = React.useState(null);
  const [CategriesDropdown, setCategriesDropdown] = React.useState(false);
  const [Customization_data, setCustomization_data] = React.useState([]);

  const handleClick = () => {
    setOpen(!open);
  };
  let textColor = "white";
  const handlesubmit = (e) => {
    e.preventDefault();
    const start_num = e.target.starting_num.value;
    const end_num = e.target.ending_num.value;
    // props.handlerange(start_num,end_num)
    // props.handelchange()
  };

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const getLocationApps = async () => {
    const { response } = await AppService.location_apps.filter({
      query: `locationId=${location_id}`,
    });
    console.log(response, "getLocationApps");
    if (response) {
      var newArray = response.data.filter(function(item) {
        return item.purchase_type=="all_app_subs";
      });
      if(newArray[0]){

        setallapps(true);
      }
      setallappsloader(false);
    }else{
      setallappsloader(false);
    }
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(async () => {
    // alert(location_id)
    const brand = await localforage.getItem("brand");
    getLocationApps()
    setuser_data(brand);
    // setCategriesDropdown(localagency?.Customization.FeaturesButton.CategriesDropdown)
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  const categories = (
    <>
      <Grid container>
        <ListItemButton onClick={handleClick} sx={{ pl: 8 }}>
          <ListItemText
            primary="Categories"
            className="defeult-theme-side-nave-text"
            primaryTypographyProps={{ variant: "body2" }}
          />
          {open ? (
            <ExpandLess
              style={{ color: "white", fontWeight: "200", fontSize: "15px" }}
            />
          ) : (
            <ExpandMore
              style={{ color: "white", fontWeight: "200", fontSize: "15px" }}
            />
          )}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          style={{ width: "100%" }}
        >
          {routes.map((category, key) =>
            category.type == "collapse" && category.sidnavtype == "category" ? (
              <NavLink
                key={category.key}
                to={`.${category.route.replace("/*", "")}`}
                className="defeult-theme-list"
              >
                <ListItem component="li" mt={1}>
                  <MDBox
                    sx={(theme) =>
                      collapseItem(theme, {
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                        sidenavColor,
                      })
                    }
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primary={category.name}
                        sx={(theme) =>
                          collapseText(theme, {
                            miniSidenav,
                            transparentSidenav,
                            whiteSidenav,
                          })
                        }
                      />
                    </ListItemButton>
                  </MDBox>
                </ListItem>
              </NavLink>
            ) : (
              <></>
            )
          )}
        </Collapse>
      </Grid>
    </>
  );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, sidnavtype }) => {
      let returnValue;
      if (parent_path != "settings") {
        if (type === "collapse") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                sidnavtype={sidnavtype}
              />
            </Link>
          ) : agency?.Customization?.FeaturesButton?.CategriesDropdown ==
            true ? (
            sidnavtype == "main" ? (
              <NavLink
                key={key}
                to={`.${route.replace("/*", "")}`}
                className={
                  agency?.Customization?.ghlTheme
                    ? "ghl-theme-list"
                    : "defeult-theme-list1"
                }
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  agency_data={agency}
                />
              </NavLink>
            ) : (
              <></>
            )
          ) : (
            <NavLink
              key={key}
              to={`.${route.replace("/*", "")}`}
              className={
                agency?.Customization?.ghlTheme
                  ? "ghl-theme-list"
                  : "defeult-theme-list1"
              }
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                agency_data={agency}
              />
            </NavLink>
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
      }
      if (type === parent_path) {
        returnValue =
          agency?.Customization?.FeaturesButton?.CategriesDropdown == true ? (
            sidnavtype == "main" ? (
              <NavLink
                key={key}
                to={`.${route.replace("/*", "")}`}
                className={
                  agency?.Customization?.ghlTheme
                    ? "ghl-theme-list"
                    : "defeult-theme-list1"
                }
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  agency_data={agency}
                />
              </NavLink>
            ) : (
              <></>
            )
          ) : (
            <NavLink
              key={key}
              to={`.${route.replace("/*", "")}`}
              className={
                agency?.Customization?.ghlTheme
                  ? "ghl-theme-list"
                  : "defeult-theme-list1"
              }
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                agency_data={agency}
              />
            </NavLink>
          );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      agency_data={agency}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={1} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <MDBox component="img" src={brand} alt="Brand" width="2rem" />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {!allappsloader ?
      allapps ?
      <></>
      :
      <MDBox p={2}>
        <MDButton
          // component="a"
          // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          // target="_blank"
          // rel="noreferrer"
          onClick={() =>
            navigate(
              `/store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency._id}/ghlapps/getallapps`
            )
          }
          variant="gradient"
          color={sidenavColor}
          fullWidth
          className={
            agency?.Customization?.ghlTheme
              ? "ghl-theme-sidnav-button"
              : "sidnav-button"
          }
        >
          Get Access All Apps
        </MDButton>
      </MDBox>
      :
      <></>
      }
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {parent_path == "settings" ? (
        <MDBox pb={3} pl={2}>
          <MDButton
            size="small"
            onClick={() =>
              navigate(
                `/store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency._id}/Snapshot`
              )
            }
          >
            Go Back
          </MDButton>
        </MDBox>
      ) : (
        <MDBox />
      )}
      <List>
        {renderRoutes}
        {agency?.Customization?.FeaturesButton?.CategriesDropdown == true ? (
          categories
        ) : (
          <></>
        )}
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        {/* <MDBox p={2} mt={level == 'agency' ? 0 : 'auto'}>

          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }} onSubmit={handlesubmit}>
            <Grid container>
              <Grid item sm={10} sx={{ marginTop: 2, fontWeight: '200' }} >
                <label htmlFor="" className='defeult-theme-side-nave-text' style={{ marginLeft: '8px', fontSize: '15px' }}>Only Free</label>
              </Grid>
              <Grid item sm={2} sx={{ display: "flex", marginTop: 3, fontSize: "15px", justifyContent: "end" }} >
                <input type="checkbox" name='starting_num' className='' style={{ height: "22px", width: '22px' }} />
              </Grid>
              <Grid item sm={12} sx={{ marginTop: 4 }}>
                <label htmlFor="" className='defeult-theme-side-nave-text' style={{ marginLeft: '8px', fontSize: '15px' }}>Price Filter</label>

              </Grid>

              <Grid item sm={5}>

                <FormControl sx={{ margin: '3px', fontSize: '13px' }}>
                  <input type="number" placeholder="$ Start" name='starting_num' className='form-control' style={{ height: '35px' }} />

                </FormControl>
              </Grid>
              <Grid item sm={5}>

                <FormControl sx={{ margin: '3px', fontSize: '13px' }}>
                  <input type="number" placeholder="$ End" name='ending_num' className='form-control' style={{ height: '35px' }} />

                </FormControl>
              </Grid>
              <Grid item sm={2} sx={{ marginLeft: '-7px' }} >
                <button type='submit' className='btn btn-pill text-white' >

                  <DoneIcon fontSize='medium' sx={{ width: "1.2em", height: "1.2em" }} />
                </button>
              </Grid>

            </Grid>
          </Box>

        </MDBox> */}
      </List>

      {level == "agency" ? (
        <MDBox p={2} pb={0} mt="auto">
          <MDButton
            // component="a"
            // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
            // target="_blank"
            // rel="noreferrer"
            onClick={() =>
              window.open("https://university.snapshotstore.io/", "_blank")
            }
            variant="gradient"
            color={sidenavColor}
            fullWidth
          >
            University
          </MDButton>
        </MDBox>
      ) : (
        <></>
      )}

      <MDBox p={2} mt={level == "agency" ? 0 : "auto"}>
        {user_data ? (
          <MDButton
            // component="a"
            // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
            // target="_blank"
            // rel="noreferrer"
            onClick={() => navigate(`./settings/profile`)}
            variant="gradient"
            color={sidenavColor}
            fullWidth
            className={
              agency?.Customization?.ghlTheme
                ? "ghl-theme-sidnav-button"
                : "sidnav-button"
            }
          >
            Account
          </MDButton>
        ) : (
          <MDButton
            // component="a"
            // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
            // target="_blank"
            // rel="noreferrer"
            onClick={() => navigate(`./auth`)}
            variant="gradient"
            color={sidenavColor}
            fullWidth
            className={
              agency?.Customization?.ghlTheme
                ? "ghl-theme-sidnav-button"
                : "sidnav-button"
            }
          >
            Login
          </MDButton>
        )}
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  level: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
