import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import localforage from "localforage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import MDButton from "components/MDButton";
import PurchasesDetailsModal from "./components/PurchasesDetailsModal";
import IntegrationModal from "./components/IntegrationModal";
import env from "config";
import { RemoveRedEye } from "@mui/icons-material";
import MDBox from "components/MDBox";
import ConfirmAlert from "./components/ConfirmAlert";
import { useUserInfo } from "context/user";
import { useAgencyInfo } from "context/agency";

function Default() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const searchParams = new URLSearchParams(document.location.search);
  const params = useParams();
  const updateUser = useUserInfo()[1];
  const location_settings = useAgencyInfo()[5];
  const updateAgency = useAgencyInfo()[6];

  console.log(location_settings, "location_settings");
  const [processing, setprocessing] = useState(true);
  const [Conjo, setConjo] = useState([]);
  const [sessionkey, setsessionkey] = useState(searchParams.get("sessionkey"));
  const { pathname } = useLocation();
  const locationId = params.location_id;
  const agency_id = params.agency_id;
  var loc_name = params.loc_name;
  var loc_add = params.loc_add;
  // alert(locationId);
  if (searchParams.get("sessionkey") != null) {
    localforage.setItem("myapps_data", {
      locationId,
      loc_name,
      loc_add,
      agency_id,
      sessionkey,
    });
  }
  const loc_data = {
    location_id: locationId,
    agency_id: agency_id,
    loc_name: loc_name,
    loc_add: loc_add,
    agency_id: agency_id,
  };
  localforage.setItem("loc_data", loc_data);
  const handleAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 4000);
  };
  const getAgencySettings = async (data) => {
    const { response } = await AppService.agency.location_settings({
      query: `agency_id=${agency_id}`,
    });
    if (response) {
      makeData(data, response.data);
    }
  };
  const getApps = async (e) => {
    console.log(location_settings, "location_settingsgetApps");

    const myapps_data = await localforage.getItem("myapps_data");
    const { response } = await AppService.location_apps.getmyapps({
      query: `locationId=${locationId}& agency_id=${agency_id}`,
    });
    if (response) {
      await getAgencySettings(response.data);
      setprocessing(false);
    } else {
      setprocessing(false);
    }
  };
  const makeData = async (data, Settings) => {
    const tabel_data = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const locationSettings = await localforage.getItem("locationSettings");
      console.log(element, "element");
      const price = `${Settings?.currency_sign || ""} ${
        element.location_app.agency_sub.selling_price.value.substr(
          0,
          element.location_app.agency_sub.selling_price?.value.indexOf(" ")
        ) || "0"
      }`;
      const temp = [
        element.app_data.name,
        element.app_data.app_type.value,
        element.location_app.status,
        price,
        <>
          {sessionkey == undefined || sessionkey == null || sessionkey == "" ? (
            <MDButton
              onClick={handleAlert}
              variant="contained"
              color={element.location_app?.ghl ? "success" : "info"}
              size="small"
            >
              {element.location_app?.ghl ? "Connected" : "Connect"}
            </MDButton>
          ) : (
            <MDButton
              variant="contained"
              color={element.location_app?.ghl ? "success" : "info"}
              size="small"
            >
              <a
                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.LOCATION}&client_id=${element.app_data.client_id}&scope=${env.GHL.SCOPE}&state=${element.app_data.app_id},${locationId},${agency_id}&sessionKey=${sessionkey}`}
                style={{ color: "inherit" }}
              >
                {element.location_app?.ghl ? "Connected" : "Connect"}
              </a>
            </MDButton>
          )}
          {element.app_data?.integration_type == "api_key" ? (
            <IntegrationModal
              data={element.location_app}
              handleRefresh={onLoad}
            />
          ) : (
            <></>
          )}
        </>,
        <PurchasesDetailsModal data={element} />,
      ];
      tabel_data.push(temp);
    }
    setConjo(tabel_data);
  };
  const onLoad = async () => {
    // const myapps_data = await localforage.getItem("myapps_data");
    // if (myapps_data != null) {
    //   setsessionkey(myapps_data?.sessionkey);
    //   setlocationId(myapps_data?.location_id);
    //   setagency_id(myapps_data?.agency_id);
    // }
    updateAgency();
    getApps();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Invoice",
    //   options: {
    //     filter: false,
    //   },
    // },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const handleLogout = async () => {
    localforage.removeItem("brand");
    const data = {
      locationId: locationId,
      agency_id: agency_id,
    };
    updateUser({});
    window.location.reload(false);
    // navigate("/auth/sign-in");
  };
  return (
    <MDBox>
      <ConfirmAlert
        handleSubmit={handleAlert}
        state={state}
        text={"Your Session Key is Missing Please Refresh Your Page !"}
      />
      {/* <MDBox display="flex" justifyContent="flex-end" mx={8} mt={4}>
        <MDButton
          sx={{ marginLeft: "auto" }}
          variant="contained"
          color={"info"}
          size="small"
          onClick={handleLogout}
        >
          Logout
        </MDButton>
      </MDBox> */}
      <MDBox m={8} mt={5}>
        {processing ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </MDBox>
  );
}

export default Default;
