import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";
import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "./style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAppServices } from "hook/services";
import BundlePurchaseModal from "./Components/bundlePurchaseModal";
import { useAgencyInfo } from "context/agency";
import DetailModal from "./Components/PurchasesDetailsModal";
import { useLocation } from "react-router-dom";

function Default({ purchased_apps }) {
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [errormsg, seterrormsg] = useState("");
  const [refresh, setrefresh] = useState(false);
  const location_settings = useAgencyInfo()[5];
  const { pathname } = useLocation();
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const MakePrice = (item) => {
    var totalprice = 0;
    if (item.setup_payable == "yes") {
      totalprice += parseFloat(
        item.setup_fee.substr(0, item.setup_fee.indexOf("("))
      );
    }
    if (item.management_payable == "yes") {
      totalprice += parseFloat(
        item.management_sub.substr(0, item.management_sub.indexOf("("))
      );
    }
    for (let index = 0; index < item.apps.length; index++) {
      const element = item.apps[index];
      var newArray = purchased_apps.filter(function (e) {
        return (
          e.app_id ===
          element.value.substring(
            element.value.indexOf("(") + 1,
            element.value.indexOf(")", element.value.indexOf("("))
          )
        );
      });
      console.log(newArray);
      if (newArray.length) {
        var temp_price = newArray[0].selling_price?.value.substr(
          0,
          newArray[0].selling_price?.value.indexOf(" ")
        );
        console.log(
          newArray[0].selling_price?.value.substr(
            0,
            newArray[0].selling_price?.value.indexOf(" ")
          ),
          "price"
        );
        totalprice += parseFloat(temp_price);
      } else {
        totalprice += parseFloat(
          element.value.substr(0, element.value.indexOf(" "))
        );
      }
    }
    return totalprice;
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "User",
      options: {
        filter: false,
      },
    },
    {
      name: "Date",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Intended Location",
    //   options: {
    //     filter: false,
    //   },
    // },
    {
      name: "Connected Location",
      options: {
        filter: false,
      },
    },
    {
      name: "View Details",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getPurchases = async () => {
    const loc_data = await localforage.getItem("loc_data");
    const user_data = await localforage.getItem("brand");
    const { response } = await Service.Purchase_snaphot.getpurchases({
      query: `user_id=${user_data._id}`,
    });
    console.log(response, "response");
    if (response) {
      var tabel_data = [];
      response.data?.forEach((element) => {
        var price = 0;
        var type = "";
        var locationId = "";
        var connectedLocation = "";
        if (
          element.prod?.type == "snapshot" ||
          element.prod?.type == "service" ||
          element.prod?.type == "reselling_service"
        ) {
          price = element?.purchase_prod.price;
          locationId = element?.purchase_prod.loc_id;
          type = element?.prod.type;
          if (element.prod?.type == "reselling_service") {
            type = "service";
          }
        } else if (
          element.prod?.type == "bundle" ||
          element.prod?.type == "reselling_bundle"
        ) {
          console.log(element.prod);
          price = element.purchase_prod.price;
          type = "bundle";
          locationId = element?.purchase_prod.loc_id;
        } else {
          price = element?.selling_price;
          if (element?.prod?.app_type?.label == "Both") {
            type = element?.prod?.app_type?.value;
          } else {
            type = element?.prod?.app_type?.label;
          }
          locationId = element?.purchase_prod?.locationId;
          if (element.purchase_prod?.ghl?.location_id) {
            connectedLocation = element?.purchase_prod?.ghl?.location_id;
          } else {
            connectedLocation = "Pending Connection";
          }
        }

        if (element.prod?.type == "bundle") {
          var temp = [
            element.prod.name,
            type,
            location_settings.currency_sign + price || "0",
            element.user_data.email,
            element.purchase_prod.createdAt,
            // locationId,
            connectedLocation,
            <BundlePurchaseModal data={element} />,
          ];
        } else {
          var temp = [
            element.prod.name,
            type,
            location_settings.currency_sign + price || "0",
            element.user_data.email,
            element.purchase_prod.createdAt,
            // locationId,
            connectedLocation,
            <DetailModal data={element} />,
          ];
        }
        if (element?.purchase_prod?.purchase_type != "bundle") {
          tabel_data.push(temp);
        } else {
          console.log(
            element?.purchase_prod?.purchase_type,
            "element?.purchase_prod"
          );
        }
      });
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getPurchases();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <Grid container>
          {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
          {errormsg != "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errormsg}</Alert>
            </Stack>
          ) : (
            <Grid item xs={12} className="custom-datatable">
              <MUIDataTable data={Conjo} columns={columns} options={options} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default Default;
