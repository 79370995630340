/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import FoegetPassword from "./FoegetPassword";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import localforage from "localforage";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useState } from "react";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import env from "config";
// Images
import bgImage from "assets/images/GHL.store logo.png";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../DashboardNavbar";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useUserInfo } from "context/user";
import Login from "./Login";
import Register from "./Register";

function Form() {
  const [error, seterror] = useState("");
  const [message, setmessage] = useState("");
  const [form_type, setform_type] = useState("login");
  let navigate = useNavigate();
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const updateUser = useUserInfo()[1];

  const handlemessage = async (e, type) => {
    if (type == "true") {
      setmessage(e);
    } else {
      seterror(e);
    }
    setTimeout(() => {
      seterror("");
      setmessage("");
    }, 3000);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var axios = require("axios");

    const localBrand = await localforage.getItem("loc_data");

    const email = e.target.email.value;
    const password = e.target.password.value;
    var path = pathname.split("/")[1];
    var loc_name = pathname.split("/")[4].replace(/\s/g, "");
    var type = decodeURIComponent(pathname.split("/")[2]);
    var loc_add = pathname.split("/")[5].replace(/\s/g, "");

    const data = {
      email: email,
      password: password,
      location_id: localBrand.location_id,
    };
    console.log(data, "data");

    axios
      .post(BASE_URL + "/auth/userlogin", { data })
      .then(async function (response) {
        if (response.data.success == true) {
          localforage.setItem("brand", response.data.data);
          updateUser(response.data.data);

          navigate(
            `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/Snapshot`
          );
        }
      })
      .catch(function (error) {
        seterror(error.response.data.message);
        setTimeout(() => {
          seterror("");
        }, 3000);
      });
  };
  const handlForm = (e) => {
    setform_type(e);
  };
  return (
    <>
      <BasicLayout>
        {form_type == "login" ? (
          <Login handlForm={handlForm} />
        ) : (
          <Register handlForm={handlForm} />
        )}
      </BasicLayout>
    </>
  );
}

export default Form;
