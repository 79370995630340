/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import orderImage from "assets/images/bg-profile.jpeg";

function OrderInfo({ data }) {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDAvatar
              size="xxl"
              src={data.app_data.image ||  orderImage}
              alt="Gold Glasses"
            />
          </MDBox>
          <MDBox lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {data.app_data?.name}
            </MDTypography>
            <MDBox mb={2}>
              <MDTypography variant="button" color="text">
                {data.app_data?.description}
              </MDTypography>
            </MDBox>
            {/* <MDBadge
              variant="gradient"
              color="success"
              size="xs"
              badgeContent="delivered"
              container
            /> */}
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default OrderInfo;
