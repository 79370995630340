/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import MDModal from "components/MDModal";
import { useState } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import env from "config";
// import MDInput from 'components/MDInput'
// import { TextField } from '@mui/material'
import StripeInput from "../StripeInput";
import MDInput from "components/MDInput";
import { CircularProgress, Switch } from "@mui/material";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useUserInfo } from "context/user";
import { useFormik } from "formik";
import localforage from "localforage";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import React, { useEffect } from "react";
const BASE_URL = `${env.API_URL}/v1`;

function AddPaymentMethod({
  open,
  onClose,
  handlemessage,
  handlerefresh,
  stripe_data,
}) {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [user] = useUserInfo();
  const element = useElements();
  const stripe = useStripe();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  // const [stripe_data, setstripe_data] = useState("");

  const initState = {
    name: "",
    make_default: false,
  };
  var axios = require("axios");

  useEffect(async () => {
    const localBrand = await localforage.getItem("loc_data");
    const brand = await localforage.getItem("brand");
    // setlocation_id()
    // alert(BASE_URL)
    //   console.log(localBrand.stripe.customer_id)
    //  setcustomer_id(localBrand.stripe.customer_id)
    axios
      .get(BASE_URL + "/agency/main_agency/" + localBrand.agency_id)
      .then(async function (response) {
        // setstripe_key(response.data.data.stripe.publish_key);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const localBrand = await localforage.getItem("loc_data");
    const user = await localforage.getItem("brand");

    // var make_default = event.target.switch.checked;
    var name = event.target.name.value;

    if (element == null) {
      return;
    }
    let paymentMethod = {};
    console.log(element.getElement(CardElement));
    const source = await stripe.createSource(element.getElement(CardElement));
    paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: element.getElement(CardElement),
    });
    console.log(paymentMethod, "paymentMethod");

    // 4848 7150 5203 7927 0827 711

    // console.log(source);
    // console.log(error)
    const data = {
      name: name,
      email: user.email,
      phone: user.phone,
      make_default: true,
      paymentMethod: paymentMethod.paymentMethod,
      source_id: source.source.id,
      agency_id: agency._id,
      location_id: localBrand.location_id,
      user_id: user._id,
    };
    const payload = {
      data: data,
    };
    // 4921 8197 1480 0316
    console.log(data);
    if (paymentMethod.error) {
      // handleClose();
      // seterror(source.error.message)
    } else {
      if (stripe_data == "") {
        console.log(data);
        const { response, error } = await AppService.stripe.create({ payload });
        if (response) {
          setProcessing(false);

          handlemessage(response.message, "success");
          handlerefresh(true);
          onClose();
        } else {
          handlemessage(error?.response?.message, "error");
          onClose();
          setProcessing(false);
        }
        // axios
        //   .post(BASE_URL + "/snapshot/stripe/submit", { data })
        //   .then(async function (response) {
        //     console.log(response.data);
        //     setProcessing(false);

        //     handlemessage(response.data.message, "success");
        //     handlerefresh(true);
        //     onClose();

        //     // setmessage(response.data.message)
        //     // seterror('')

        //     // handleClose();
        //   })
        //   .catch(function (error) {
        //     // handleClose();

        //     console.log(error.response);
        //     handlemessage(error.response.data.message, "error");
        //     onClose();
        //     setProcessing(false);
        //     // setmessage('')
        //     // seterror(error.response.data.message)
        //   });
      } else {
        const { response, error } = await AppService.stripe.update({
          payload,
          id: stripe_data._id,
        });
        if (response) {
          setProcessing(false);

          handlemessage(response.message, "success");
          handlerefresh(true);
          onClose();
        } else {
          handlemessage(error?.response?.message, "error");
          onClose();
          setProcessing(false);
        }
        // axios
        //   .post(BASE_URL + "/snapshot/stripe/update/" + stripe_data._id, {
        //     data,
        //   })
        //   .then(async function (response) {
        //     setProcessing(false);
        //     handlemessage(response.data.message, "success");
        //     onClose();

        //     console.log(response.data);
        //   })
        //   .catch(function (error) {
        //     setProcessing(false);

        //     handlemessage(error.response.data.message, "error");
        //     onClose();

        //     console.log(error.response);
        //   });
      }
      handlerefresh();
    }
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
  });

  return (
    <MDModal open={open} onClose={onClose} width={500} height={700}>
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDInput
          fullWidth
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardElement,
            },
          }}
        />
        <MDBox mt={2}>
          <MDInput
            label="Name"
            name="name"
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
            success={formik.touched.name && !formik.errors.name}
            fullWidth
          />
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Make Primary</MDTypography>

          <Switch
            name="make_default"
            checked={formik.values.make_default}
            onChange={() =>
              formik.setValues({
                ...formik.values,
                make_default: !formik.values.make_default,
              })
            }
          />
        </MDBox>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          loading={processing}
          disabled={processing}
          sx={{ mt: 4, mb: 1 }}
          fullWidth
        >
          Submit
        </MDButton>
        <MDTypography variant="button" color="error">
          {error}
        </MDTypography>
      </MDBox>
    </MDModal>
  );
}

function RemoveCardConfirmation({
  open,
  onClose,
  paymentMethod,
  updateCardList,
}) {
  const AppService = useAppServices();

  const [processing, setProcessing] = useState(false);

  const removeCard = async () => {
    setProcessing(true);
    const { response } = await AppService.agency.payment_method_remove({
      toaster: true,
      payload: { payment_method_id: paymentMethod.id },
    });
    setProcessing(false);
    response && updateCardList();
  };

  return (
    <MDModal open={open} onClose={onClose}>
      <MDBox>
        <MDTypography>
          Are you sure you want to delete this payment method
        </MDTypography>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDButton
            variant="gradient"
            color="info"
            loading={processing}
            disabled={processing}
            sx={{ mt: 4, mb: 1, mr: 2 }}
            onClick={removeCard}
            fullWidth
          >
            Yes
          </MDButton>
          <MDButton
            variant="outlined"
            color="info"
            sx={{ mt: 4, mb: 1, ml: 2 }}
            onClick={onClose}
            fullWidth
          >
            Cancel
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  );
}

function PaymentMethod({
  paymentMethodState,
  customerState,
  handlemessage,
  handleRefresh,
  stripe_data,
}) {
  const { paymentMethods, setPaymentMethods } = paymentMethodState;
  const { customer, setCustomer } = customerState;

  const [agency] = useAgencyInfo();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [removeCard, setRemoveCard] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [stripe_data, setstripe_data] = useState("");
  const [stripe_key, setstripe_key] = useState("");

  const stripePromise = loadStripe(stripe_key);

  const AppService = useAppServices();
  var axios = require("axios");

  useEffect(async () => {
    const localBrand = await localforage.getItem("loc_data");
    const brand = await localforage.getItem("brand");
    const { response } = await AppService.agency.get({
      query: `_id=${localBrand.agency_id}`,
    });
    if (response) {
      setstripe_key(response.data.stripe.publish_key);
      console.log(response.data.stripe.publish_key);
    } else {
      // alert(localBrand.agency_id);
    }
  }, [handleRefresh]);

  const getPaymentMethods = async () => {
    const { response } = await AppService.agency.get_payment_methods({
      query: `customer_id=${agency.stripe?.customer_id}`,
    });
    if (response) {
      setPaymentMethods(response.data);
    }
  };

  const handleClose = () => {
    setAddPaymentMethod(false);
    getPaymentMethods();
  };

  const updateCardList = (idx) => {
    paymentMethods.splice(idx, 1);
    setPaymentMethods([...paymentMethods]);
    setRemoveCard(false);
  };

  const MakePrimary = async (paymentMethod) => {
    setProcessing(true);
    const payload = {
      customer_id: agency.stripe?.customer_id,
      payment_method_id: paymentMethod.id,
    };

    const { response } = await AppService.agency.update_payment_methods({
      toaster: true,
      payload,
    });

    setProcessing(false);

    setCustomer(response.data);
  };

  return (
    <>
      <MDBox
        pt={2}
        pl={8}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Payment Method
        </MDTypography>
        {stripe_data == "" ? (
          <MDButton
            onClick={() => setAddPaymentMethod(true)}
            variant="gradient"
            color="dark"
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new card
          </MDButton>
        ) : (
          <MDButton
            onClick={() => setAddPaymentMethod(true)}
            variant="gradient"
            color="success"
          >
            &nbsp;update your card
          </MDButton>
        )}

        <Elements stripe={stripePromise}>
          <AddPaymentMethod
            open={addPaymentMethod}
            stripe_data={stripe_data}
            onClose={handleClose}
            handlemessage={handlemessage}
            handlerefresh={handleRefresh}
          />
        </Elements>
      </MDBox>
      <MDBox p={2}>
        {/* <Grid container spacing={3}>
          {paymentMethods.map((paymentMethod, idx) => (
            <Grid key={paymentMethod.id} item xs={12} md={6}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox
                  component="img"
                  src={
                    paymentMethod.card.brand == "visa"
                      ? visaLogo
                      : masterCardLogo
                  }
                  alt="master card"
                  width="10%"
                  mr={2}
                />
                <MDTypography variant="h6" fontWeight="medium">
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                  {paymentMethod.card.last4}
                </MDTypography>
                {paymentMethod.id !=
                  customer.invoice_settings?.default_payment_method && (
                  <MDBox
                    ml="auto"
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Delete Card" placement="top">
                      <Icon
                        onClick={() => setRemoveCard(paymentMethod.id)}
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                      >
                        delete
                      </Icon>
                    </Tooltip>
                    <RemoveCardConfirmation
                      open={removeCard == paymentMethod.id}
                      onClose={() => setRemoveCard()}
                      paymentMethod={paymentMethod}
                      updateCardList={() => updateCardList(idx)}
                    />
                  </MDBox>
                )}
                {paymentMethod.id !=
                customer.invoice_settings?.default_payment_method ? (
                  <MDBox
                    ml={1}
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Make Primary" placement="top">
                      {processing ? (
                        <CircularProgress size={14} />
                      ) : (
                        <GradeOutlinedIcon
                          onClick={() => MakePrimary(paymentMethod)}
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      )}
                    </Tooltip>
                  </MDBox>
                ) : (
                  <MDBox
                    ml="auto"
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Primary Card" placement="top">
                      <GradeIcon fontSize="small" />
                    </Tooltip>
                  </MDBox>
                )}
              </MDBox>
            </Grid>
          ))}
        </Grid> */}
      </MDBox>
    </>
  );
}

export default PaymentMethod;
