/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderSummary({ data }) {
  const location_settings = useAgencyInfo()[5];

  return (
    <MDBox>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </MDBox>
      {data.prod?.type == "snapshot" || data.prod?.type == "service" ? (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Price:
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign}
              {data?.purchase_prod?.price}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Subscription:
            <br />
            <span style={{ fontSize: "13px" }}>
              ({data?.purchase_prod?.sub_id})
            </span>
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              {location_settings.currency_sign}
              {data?.selling_price}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default OrderSummary;
