/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Header(data) {
  console.log(data, "datadata");
  const navigate = useNavigate();
  return (
    <>
      <Grid container rowSpacing={0.8}>
        <Grid item xs={12}>
          <MDTypography variant="h6" fontWeight="medium">
            Invoice Details
          </MDTypography>
        </Grid>
        <Grid item xs={12} display={"flex"} flexDirection={"column"}>
          <MDBox display={"flex"} justifyContent={"space-between"}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Customer Invoice:
            </MDTypography>
            <MDButton
              variant="gradient"
              size="small"
              color="dark"
              onClick={() =>
                window.open(
                  `${data?.data?.location_app?.latest_invoice?.payment_intent?.charges?.data[0]?.receipt_url}`,
                  "_blank"
                )
              }
            >
              View invoice
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
