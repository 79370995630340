/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
// useParams

// @mui material components
// import Icon from '@mui/material/Icon'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "./Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
// import brandWhite from 'assets/images/logo-ct.png'
// import brandDark from 'assets/images/logo-ct-dark.png'

// Material Dashboard 2 React routes
// import RouteGuard from 'route.guard'
import localforage from "localforage";
import { useBrandInfo } from "context/brand";
import useRoutes from "./routes";
import Settings from "./setting/Settings";
import env from "config";
import { Params } from "react-router-dom";
import { useAgencyInfo } from "context/agency";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const params = useParams();
  const searchParams = new URLSearchParams(document.location.search);
  const BASE_URL = `${env.API_URL}/v1`;
  const [agency] = useAgencyInfo();
  const { pathname } = useLocation();

  var type = "";
  const location_id = params.location_id;
  const agency_id = params.agency_id;
  var loc_name = params.loc_name;
  var loc_add = params.loc_add;
  const sessionkey = searchParams.get("sessionkey");
  const parent_path = pathname.split("/")[8];
  if (sessionkey != null) {
    localforage.setItem("sessionkey", sessionkey);
  }
  const loc_data = {
    location_id: location_id,
    agency_id: agency_id,
    loc_name: loc_name,
    loc_add: loc_add,
    agency_id: agency_id,
  };
  localforage.setItem("loc_data", loc_data);
  const [brand] = useBrandInfo();

  const routes = useRoutes();

  const {
    miniSidenav,
    direction,
    openConfigurator,
    // sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    // console.log(loc_data)
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    // alert(searchParams.get("sessionkey"));
    localforage.setItem("loc_data", loc_data);
    var path = pathname.split("/")[3];
    type = pathname.split("/")[2].replace(/\s/g, "");
    loc_name = pathname.split("/")[4].replace(/\s/g, "");
    loc_add = pathname.split("/")[5].replace(/\s/g, "");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  const configsButton = (
    <MDBox
      // display="flex"
      // justifyContent="center"
      // alignItems="center"
      // width="3.25rem"
      // height="3.25rem"
      // bgColor="white"
      // shadow="sm"
      // borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  return (
    <>
      {agency?.Customization?.navbar?.Categries ? (
        <></>
      ) : parent_path == "auth" ? (
        <></>
      ) : (
        <Sidenav
          // color={sidenavColor}
          // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Configurator />
      {configsButton}
      <Routes>
        {/* <Route element={<RouteGuard valid={brand._id || location_id} redirect="/404" />}> */}
        {getRoutes(routes)}
        <Route exact path="/settings" element={<Settings />} />
        <Route
          path="*"
          element={
            <Navigate
              to={`store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency_id}/Snapshot`}
            />
          }
        />
        {/* </Route> */}
      </Routes>
    </>
  );
}
