
import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'
import MDInput from 'components/MDInput'
import Grid from '@mui/material/Grid'
import { Select } from 'antd';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button'
import MDBox from 'components/MDBox'
import env from 'config'
import MDTypography from 'components/MDTypography'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import localforage from 'localforage'
const FoegetPassword = (props) => {
  const BASE_URL = `${env.API_URL}/v1`

  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [agency_id, setagency_id] = useState()

  useEffect(async() => {
    const localBrand = await localforage.getItem('loc_data');
    setagency_id(localBrand.agency_id)
  }, [])
    function AddProduct({ open, onClose }) {
    
        const initState = {
          name: '',
        }
        const [processing, setProcessing] = useState(false)
        const [email, setemail] = useState('')
        
        const handleForgetPassword = (e) => {
        }
        const handleSubmit = async (e) => {
      e.preventDefault();

        var axios = require('axios')
    
          setProcessing(true)
          const data={
            email:email,
    
          }
          console.log(data)
          axios.post(BASE_URL+'/auth/forget/'+agency_id, { data }) .then(async function (response) {
            console.log(response.data);
            props.handlemessage('Your Request Submitted Successfully','true')
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessage('Your Request Not Submitted Successfully','false')


          });
          // const { response } = await AppService.stripe.addSubscription({ payload })
        setOpenAddProduct(false)
          
          // setProcessing(false)
          // onClose(response?.data)
        }
        const handleSelectChange = (e, { value }, name) => {
          formik.setValues({ ...formik.values, [name]: value })
        }
        
        const formik = useFormik({
          initialValues: { ...initState },
          onSubmit: handleSubmit,
        })
        return (
          <MDModal open={open} onClose={onClose}>
          <MDBox>
          <MDTypography  variant="button" display="block" gutterBottom>
                  What is Your Email Address?
                </MDTypography>
      
              <MDBox component="form"  role="form" sx={{ width: 335 }} id={'forget_form'}>
              <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput
                  type="text"
                  label="email address"
                  name="email"
                  fullWidth
                  onChange={e => setemail(e.target.value)}
                />
              </MDBox>
            </Grid>
      
                <MDBox>
                
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="button"
                    sx={{ mt: 4, mb: 1 }}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDModal>
        )
      }
      const closeAddProduct = (subscription) => {
        // if (subscription?._id) 
        setOpenAddProduct(false)
      }
  return (
    <>
        {/* <Button mr={2} sx={{ mr:2 }} variant="contained" type="submit" color="primary" className="text-light btn-success" 
       
        >
            ADD Category
          </Button> */}
          
          <MDTypography variant="subtitle2"  color="black"  sx={{cursor:'pointer',textAlign:'right'}}  onClick={() => setOpenAddProduct(true)}>
            forgot password?
          </MDTypography>
                <AddProduct open={openAddProduct} onClose={closeAddProduct} />

    </>
  )
}

export default FoegetPassword