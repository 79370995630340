import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
// import { Grid } from "@mui/material";
// import { Card } from "@material-ui/core";
import moment from "moment";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
// import env from "config";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import DefaultProjectCard from "./components/DefaultProjectCard";
import { useAppServices } from "hook/services";
// import { useUserInfo } from "context/user";
import localforage from "localforage";
import ConfirmAlert from "./components/ConfirmAlert";
import useRoutes from "../routes";
// import { useAgencyInfo } from "context/agency";
import { useLocation, useNavigate } from "react-router-dom";

function Workflow() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [Apps, setApps] = useState([]);
  const [categories, setcategories] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [processing, setprocessing] = useState(true);
  const [text, settext] = React.useState("");
  const { pathname } = useLocation();
  //   const routes = useRoutes();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);
  //   const handlerefresh = async (e) => {
  //     setrefresh(!refresh);
  //   };
  const getCategories = async (e) => {
    var axios = require("axios");
    const { response } = await AppService.categories.get({
      query: "created_by=superadmin",
    });
    if (response) {
      var temp_cat = [];
      // temp_cat = response.data;
      // const temp = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var newArray = element.type.filter(function (item) {
          return item.value == "app";
        });
        if (newArray.length) {
          temp_cat.push(element);
        }
      }
      temp_cat.sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
      setcategories(temp_cat);
    }
  };
  const getApps = async (e) => {
    const { response } = await AppService.app.get();
    if (response) {
      var newArray = response.data.filter(function (item) {
        return item.status.value == "active";
      });
      console.log(newArray);
      setApps(newArray);
    } else {
      setApps([]);
    }
  };
  const onLoad = () => {
    getCategories();
    getApps();
    Promise.all([getCategories(), getApps()])
      .then((results) => {
        setprocessing(false);
      })
      .catch((error) => {
        setprocessing(true);
      });
  };

  useEffect(async () => {
    onLoad();
  }, [refresh]);
  const handleAlert = async (text, alert_type) => {
    const localBrand = await localforage.getItem("loc_data");
    //     setState({ ...state, open: true });
    //     settext(text);
    //     // alert(alert_type)
    //     if (alert_type == "auth") {
    //       setTimeout(() => {
    //         navigate(
    //           `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/auth`
    //         );
    //       }, 2000);
    //     } else if (alert_type == "session key") {
    //       setTimeout(() => {
    //         setState({ ...state, open: false });
    //       }, 4000);
    //     } else {
    //       setTimeout(() => {
    //         navigate(
    //           `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/setting
    // s/payment`
    //         );
    //       }, 2000);
    //     }
  };

  return (
    <>
      <DashboardLayout>
        {/* <ConfirmAlert handleSubmit={handleAlert} state={state} text={text} /> */}
        {processing ? (
          <Loader />
        ) : (
          <ComplexStatisticsCard
            handleAlert={handleAlert}
            Apps={Apps}
            category={categories}
            handlerefresh={onLoad}
          />
        )}
      </DashboardLayout>
    </>
  );
}

export default Workflow;
