import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import localforage from "localforage";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import BuyModal_iframe from "../components/BuyModal_iframe";
import BuyOfferModal from "../components/BuyOfferModal ";
import env from "config";
import ConfirmAlert from "../components/ConfirmAlert";
import { useAppServices } from "hook/services";
import Loader from "examples/Loader";
function Iframs() {
  const AppService = useAppServices();
  const [snapshot_data, setsnapshot_data] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [processing, setProcessing] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [purchased, setpurchased] = useState([]);
  const [text, settext] = React.useState("");
  const [configure_apps, setconfigure_apps] = useState([]);
  const [location_data, setlocation_data] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);

  const BASE_URL = `${env.API_URL}/v1`;
  const snapshot_id = params.id;
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };

  const handelchange = async () => {
    setrefresh(!refresh);
  };
  const handleMessage = () => {};
  const getAppconfiguration = async (apps_data) => {
    const loc_data = await localforage.getItem("loc_data");

    const { response } = await AppService.purchased_apps.filter({
      query: `agency_id=${loc_data.agency_id}`,
    });
    if (response) {
      setconfigure_apps(response.data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };

  const getAppPurchased = async () => {
    const loc_data = await localforage.getItem("loc_data");

    const { response } = await AppService.location_apps.filter({
      query: `locationId=${loc_data.location_id}`,
    });
    if (response) {
      console.log(response, "getAppPurchasedgetAppPurchased");
      setlocation_data(response.data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  useEffect(async () => {
    const loc_data = await localforage.getItem("loc_data");
    var axios = require("axios");
    // alert(snapshot_id)
    axios
      .get(BASE_URL + "/snapshot/create_snapshot/filter/" + snapshot_id)
      .then(async function (response) {
        console.log(response.data);
        setsnapshot_data(response.data.data);
        // alert(response.data.data.payable)
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        BASE_URL +
          "/snapshot/create_snapshot/purchasedsnapshot/" +
          snapshot_id +
          "/" +
          loc_data.location_id
      )
      .then(async function (response) {
        console.log(response.data, "purchasedsnapshot");
        setpurchased(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    getAppconfiguration();
    getAppPurchased();
  }, [refresh]);
  const handleAlert = async (text, alert_type) => {
    const localBrand = await localforage.getItem("loc_data");
    setState({ ...state, open: true });
    settext(text);
    // alert(alert_type)
    if (alert_type == "auth") {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/auth`
        );
      }, 2000);
    } else if (alert_type == "session key") {
      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/setting
s/payment`
        );
      }, 2000);
    }
  };
  return processing ? (
    <Loader />
  ) : (
    <DashboardLayout>
      <ConfirmAlert handleSubmit={handleAlert} state={state} text={text} />

      {snapshot_data.type == "snapshot" ? (
        purchased == "" ? (
          <Grid container mt={1} pb={2}>
            <Grid item justifyContent={"start"} xs={3}>
              <MDButton onClick={() => navigate(-1)}>Go Back</MDButton>
            </Grid>

            <Grid item justifyContent={"start"} xs={9}>
              <BuyModal_iframe
                handleAlert={handleAlert}
                label={
                  snapshot_data.type == "snapshot"
                    ? snapshot_data.payable == "no"
                      ? "Load"
                      : "Buy"
                    : "Request"
                }
                data={snapshot_data}
                handleMessage={handleMessage}
                handelchange={handelchange}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid justifyContent={"start"} pb={2}>
            <MDButton onClick={() => navigate(-1)}>Go Back</MDButton>
          </Grid>
        )
      ) : (
        <Grid container mt={1} pb={2}>
          <Grid item justifyContent={"start"} xs={3}>
            <MDButton onClick={() => navigate(-1)}>Go Back</MDButton>
          </Grid>

          <Grid item justifyContent={"start"} xs={9}>
            {snapshot_data.type == "bundle" ? (
              <>
                <BuyOfferModal
                  type="iframe_page"
                  purchased_apps={configure_apps}
                  data={snapshot_data}
                  handleAlert={handleAlert}
                  purchased_data={location_data}
                  handleMessage={handleMessage}
                  handelchange={handelchange}
                />
              </>
            ) : (
              <BuyModal_iframe
                handleAlert={handleAlert}
                label={
                  snapshot_data.type == "snapshot"
                    ? snapshot_data.payable == "no"
                      ? "Load"
                      : "Buy"
                    : "Request"
                }
                data={snapshot_data}
                handleMessage={handleMessage}
                handelchange={handelchange}
              />
            )}
          </Grid>
        </Grid>
      )}

      {snapshot_data.type == "service" ? (
        <iframe style={iframeStyles} src={snapshot_data.iframe_url} />
      ) : purchased == "" ? (
        <iframe style={iframeStyles} src={snapshot_data.iframe_url} />
      ) : (
        <iframe style={iframeStyles} src={snapshot_data.doc} />
      )}
    </DashboardLayout>
  );
}

export default Iframs;
