/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import { useBrandInfo } from "context/brand";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./DefaultProjectCard";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import React, { useState, useEffect } from "react";
import localforage from "localforage";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useAgencyInfo } from "context/agency";
function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  votes,
  icon,
  handleAlert,
  data,
  category,
  purchases,
  purchasedApps,
  Apps,
  agency_data,
  cat_id,
  handlerefresh,
  handelchange,
  handleMessage,
  location_data,
  handleGrid,
}) {
  const [brand] = useBrandInfo();
  const [agency] = useAgencyInfo();
  const loc_data = localforage.getItem("loc_data");
  const { pathname } = useLocation();
  const location_settings = useAgencyInfo()[5];
  var loc_id = pathname.split("/")[3];
  var loc_name = pathname.split("/")[4];
  var loc_add = pathname.split("/")[5];
  var type = pathname.split("/")[2];

  return (
    <MDBox mt={4}>
      {category.map((category_item, key) => (
        <Card
          style={{ marginBottom: "50px" }}
          className="defeult-theme-card-Bg"
        >
          <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
            <MDBox
              className="defeult-theme-card"
              variant="gradient"
              bgColor={color}
              color={color === "light" ? "dark" : "white"}
              coloredShadow={color}
              borderRadius="xl"
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="100%"
              height="4rem"
              mt={-3}
            >
              <MDTypography
                variant="button"
                ml={3}
                fontWeight="bold"
                color="white"
              >
                {category_item.name}
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox pb={2} mt={5} px={2}>
            <Grid container spacing={4}>
              {purchases.map((item, key) =>
                (item?.categories?.findIndex(
                  (e) => e.value === category_item._id
                ) > -1 &&
                  item?.app_type?.value == "funnel app") ||
                item?.app_type?.value == "workflow & funnel app" ? (
                  <Grid item xs={12} md={3}>
                    <MDBox mb={3}>
                      <DefaultProjectCard
                        handleAlert={handleAlert}
                        image={item.image}
                        title={item.name}
                        data={item}
                        votes={votes}
                        agency_data={loc_data}
                        handlerefresh={handlerefresh}
                        handleMessage={"handleMessage"}
                        handelchange={"handelchange"}
                        location_data={"location_data"}
                        handleGrid={"handleGrid"}
                        description={
                          (item?.status.value == "comming soon" ? (
                            <>
                              <div className="snapshot-des-text">
                                <b className="snapshot-des-heading">
                                  Estimate :
                                </b>{" "}
                                {item.timeline.label}
                              </div>
                              <div className="snapshot-des-text">
                                <b className="snapshot-des-heading"> Price:</b>{" "}
                                {location_settings.currency_sign +
                                  parseFloat(
                                    item.basic.value.substr(
                                      0,
                                      item.basic.value.indexOf(" ")
                                    )
                                  ) *
                                    3}
                              </div>
                              {/* <div className="snapshot-des-text">
                                <b className="snapshot-des-heading">Type:</b>{" "}
                                {item.app_type?.label}
                              </div> */}
                            </>
                          ) : (
                            <>
                              <div className="snapshot-des-text">
                                <b className="snapshot-des-heading">
                                  Description:
                                </b>{" "}
                                {item.description}
                              </div>
                              <div className="snapshot-des-text">
                                <b className="snapshot-des-heading">Type:</b>{" "}
                                {item.app_type?.label}
                              </div>
                              <div className="snapshot-des-text">
                                <b className="snapshot-des-heading">Price:</b>{" "}
                                {location_settings.currency_sign}
                                {item?.configure ? (
                                  <>
                                    {item.selling_price?.value.substr(
                                      0,
                                      item.selling_price?.value.indexOf(" ")
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.basic?.value.substr(
                                      0,
                                      item.basic?.value.indexOf(" ")
                                    ) * 3}
                                  </>
                                )}
                              </div>
                              <div>
                                {/* <b>Price:</b> {item.payable=="no"?'Free':'$'+item.purchase_listener.substr(0, item.purchase_listener.indexOf('('))} */}
                              </div>
                            </>
                          )) ||
                          "As Uber works through a huge amount of internal management turmoil."
                        }
                        action={{
                          type: "internal",
                          route: `/store/${type}/${loc_id}/${loc_name}/${loc_add}/agency/${agency._id}/ghlapps/${item._id}/ghlappsDetail`,
                          color: "info",
                          label: "Preview",
                        }}
                        button_label="Buy"
                      />
                    </MDBox>
                  </Grid>
                ) : (
                  <></>
                )
              )}
            </Grid>
          </MDBox>
        </Card>
      ))}
    </MDBox>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    handleGrid: PropTypes.string,
    data: PropTypes.array,
    category: PropTypes.array,
    agency_data: PropTypes.array,
    location_data: PropTypes.array,
    Apps: PropTypes.array,
    purchases: PropTypes.array,
    cat_id: PropTypes.string,
    handelchange: PropTypes.func,
    handleMessage: PropTypes.func,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
