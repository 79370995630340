import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppServices } from "hook/services";
import { useUserInfo } from "context/user";
import { useAgencyInfo } from "context/agency";
import "../../Snapshot/components/style.css";
import localforage from "localforage";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function AddProduct({ open, onClose, data, handleRefresh }) {
  const [processing, setProcessing] = useState(false);
  const [agency] = useAgencyInfo();
  const [user] = useUserInfo();
  const location_settings = useAgencyInfo()[5];
  const AppService = useAppServices();
  const stripe = useStripe();
  const { pathname } = useLocation();
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  const handleSubmit = async () => {
    // alert(data.invoice.payment_intent.client_secret)
    setProcessing(true);
    const payload = {
      _id: data.purchased_app_id,
      type: data.type,
      user_id: user._id,
      agency_id: agency._id,
    };
    console.log(payload);
    const { response } = await AppService.location_apps.resubscription({
      payload,
    });
    console.log(response);
    if (response) {
      if (response.status == "completed") {
        setProcessing(false);
        onClose(false);
        handleRefresh();
      } else {
        stripe
          .confirmCardPayment(response.data.client_secret, {})
          .then(async function (result) {
            console.log(result, "resultresult");
            const status = "succeeded";
            if (status == "succeeded") {
              const payload = {
                _id: data.purchased_app_id,
                type: data.type,
                status: "succeeded",
              };
              console.log(payload, "payload");
              const update_result =
                await AppService.location_apps.confirmsubscription({
                  payload,
                });
              if (update_result.response) {
                console.log(update_result, "update_result");
                setProcessing(false);
                handleRefresh();
                onClose();
              }
            } else {
              setProcessing(false);
              // // alert("fyg");
              // onClose(true);
              // handlerefresh();
            }
          });
      }
    } else {
      setProcessing(false);
      onClose(true);
      // handlerefresh();
    }
    // stripe
    //   .confirmCardPayment(data.invoice.payment_intent.client_secret, {})
    //   .then(async function (result) {
    //     console.log(result, "resultresult");
    //     const status = "succeeded";
    //     if (status == "succeeded") {
    //       const payload = {
    //         _id: data.purchased_app_id,
    //         type: data.type,
    //         user_id: user._id,
    //         agency_id: agency._id,
    //         status: "succeeded",
    //       };
    //       console.log(payload, "payload");
    //       const update_result =
    //         await AppService.location_apps.confirmsubscription({
    //           payload,
    //         });
    //       if (update_result.response) {
    //         console.log(update_result, "update_result");
    //         setProcessing(false);
    //         handleRefresh();
    //         onClose();
    //       }
    //     } else {
    //       setProcessing(false);
    //       // // alert("fyg");
    //       // onClose(true);
    //       // handlerefresh();
    //     }
    //   });
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      <div>
        <form className="form">
          <div className="banner"></div>
          <label className="title">Get Your App Subsubcribed</label>
          <label className="title-main">{data.name}</label>

          <div className="benefits">
            <span>Description</span>
            <ul>
              <li>
                <span> {"data.description"}</span>
              </li>
            </ul>
          </div>

          <div class="modal--footer">
            <label class="price">
              <sup>{location_settings.currency_sign}</sup>
              {data.invoice?.total / 100}
            </label>
            <MDButton
              loading={processing}
              disabled={processing}
              class="upgrade-btn"
              type="button"
              onClick={handleSubmit}
            >
              Confirm to Subsubcribe
            </MDButton>
          </div>
        </form>
      </div>
    </MDModal>
  );
}
const Default = ({ data, handleRefresh }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency] = useAgencyInfo();
  console.log(agency, "agency");
  const stripePromise = loadStripe(agency.stripe.publish_key);
  return (
    <>
      <MDButton
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color={"info"}
        onClick={() => setOpenAddProduct(true)}
        size="small"
      >
        Subsubcribe
      </MDButton>
      <Elements stripe={stripePromise}>
        <AddProduct
          handleRefresh={handleRefresh}
          open={openAddProduct}
          onClose={() => setOpenAddProduct(false)}
          data={data}
        />
      </Elements>
    </>
  );
};

export default Default;
