import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
import { useAppServices } from "hook/services";
import { user } from "enums";
import { useUserInfo } from "context/user";
import ConfirmAlert from "./ConfirmAlert";
import "./style.css";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAgencyInfo } from "context/agency";

function AddProduct({
  open,
  onClose,
  data,
  stripe_data,
  handlerefresh,
  handleAlert,
}) {
  const stripe = useStripe();
  const [processing, setProcessing] = useState(false);
  const [user] = useUserInfo();
  const { pathname } = useLocation();
  const AppService = useAppServices();
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  const location_settings = useAgencyInfo()[5];
  const handleSubmit = async (newState) => {
    setProcessing(true);

    const brand = await localforage.getItem("brand");
    const loc_data = await localforage.getItem("loc_data");
    // alert(loc_data.location_id)
    const app = data;
    const test = true;
    if (stripe_data == true) {
      const payload = {
        app_id: app.app_id,
        loc_name: loc_name,
        loc_add: loc_add,
        locationId: loc_data.location_id,
        agency_id: loc_data.agency_id,
        user_id: brand._id,
      };
      const { response } = await AppService.location_apps.create({ payload });
      if (response) {
        if (response.status == "completed") {
          setProcessing(false);
          onClose(false);
          handlerefresh();
        } else {
          stripe
            .confirmCardPayment(response.data.client_secret, {})
            .then(async function (result) {
              const status = "succeeded";
              if (result?.paymentIntent?.status == "succeeded") {
                const payload = {
                  create_status: true,
                  app_id: app.app_id,
                  config_app_data: response.agency_sub,
                  locationId: loc_data.location_id,
                  user_id: brand._id,
                  loc_name: loc_name,
                  loc_add: loc_add,
                  agency_id: loc_data.agency_id,
                  sub_id: response.subscription_data.id,
                  status: "succeeded",
                };
                const update_result = await AppService.location_apps.update({
                  payload,
                });
                if (update_result.response) {
                  setProcessing(false);
                  onClose();
                  handlerefresh();
                }
              } else {
                setProcessing(false);
                onClose();
                handlerefresh();
              }
            });
        }
      } else {
        setProcessing(false);
        onClose(false);
        // handlerefresh();
      }
    } else {
      setProcessing(false);
      onClose();

      handleAlert(
        "For Purchase a app make Sure Your Card Must Be Registered!",
        "billing"
      );
    }
    // const { response } = await AppService.stripe.addSubscription({ payload })

    // setProcessing(false)
    // onClose(response?.data)
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      {/* <MDBox sx={{ width: 365 }}>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>App Name:</b> {props.data.name}
        </MDTypography>
        <MDTypography mb={1} variant="body1" sx={{ fontSize: "16px" }}>
          <b>Description:</b> {props.data.description}
        </MDTypography>
        <MDTypography variant="body1" sx={{ fontSize: "16px" }}>
          <b>Price:</b>{" "}
          {"$" +
            props.data?.selling_price.substr(
              0,
              props.data?.selling_price.indexOf(" ")
            )}
        </MDTypography>
        <MDBox component="form" role="form" sx={{ width: 365 }}>
          <MDBox className="d-flex justify-content-end" mr={2}>
            <MDButton
              variant="gradient"
              color="primary"
              type="button"
              sx={{ mt: 4, mb: 1 }}
              onClick={closeAddProduct}
            >
              close
            </MDButton>
            {/* <ConfirmAlert
              handleSubmit={handleSubmit}
              state={state}
              color={"info"}
              text={
                "For Purchase a Snapshot make Sure Your Card Must Be Registered!"
              }
              variant={"gradient"}
              buttonLabel={props.label}
            /> */}
      {/* <MDButton
              variant="gradient"
              color="info"
              sx={{ mt: 4, mb: 1, ml: 1 }}
              onClick={handleSubmit}
            >
              confirm {props.label}
            </MDButton>
          </MDBox>
        </MDBox> */}
      {/* </MDBox> */}
      <div>
        <form className="form">
          <div className="banner"></div>
          <label className="title">Get Your App Loaded</label>
          <label className="title-main">{data.name}</label>

          <div className="benefits">
            <span>Description</span>
            <ul>
              <li>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 16"
                  height="16"
                  width="16"
                >
                  <rect fill="black" rx="8" height="16" width="16"></rect>
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="1.5"
                    stroke="white"
                    d="M5 8.5L7.5 10.5L11 6"
                  ></path>
                </svg> */}
                <span>{data.description}</span>
              </li>
            </ul>
          </div>

          <div className="modal--footer">
            <label className="price">
              <sup>{location_settings?.currency_sign}</sup>
              {data?.selling_price.value.substr(
                0,
                data?.selling_price.value.indexOf(" ")
              )}
            </label>
            <MDButton
              loading={processing}
              disabled={processing}
              className="upgrade-btn"
              type="button"
              onClick={handleSubmit}
            >
              Confirm to Buy
            </MDButton>
          </div>
        </form>
      </div>
    </MDModal>
  );
}
const BuyModal = (props) => {
  const AppService = useAppServices();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const [agency] = useAgencyInfo();
  const stripePromise = loadStripe(agency.stripe.publish_key);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const inputRef = useRef();
  var path = pathname.split("/")[2];
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  var axios = require("axios");
  let navigate = useNavigate();

  const handlemodal = async () => {
    if (brand == null) {
      props.handleAlert(
        "for purchase a app make sure you must be login!",
        "auth"
      );
    } else {
      setOpenAddProduct(true);
    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    // alert(brand_data)
    setbrand(brand_data);
  }, []);

  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        variant="outlined"
        size="small"
        color="primary"
        className="purchase-btn"
        onClick={handlemodal}
      >
        {props.label}
      </MDButton>
      <Elements stripe={stripePromise}>
        <AddProduct
          open={openAddProduct}
          onClose={closeAddProduct}
          data={props.data}
          stripe_data={props.stripe_data}
          handlerefresh={props.handlerefresh}
          handleAlert={props.handleAlert}
        />
      </Elements>
    </>
  );
};

export default BuyModal;
