/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import { useBrandInfo } from "context/brand";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./DefaultProjectCard";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import React, { useState, useEffect } from "react";
import localforage from "localforage";
import AppDefaultProjectCard from "./AppsDefaultProjectCard";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  data,
  purchased_apps,
  handleAlert,
  category,
  agency_data,
  cat_id,
  stripe_data,
  handelchange,
  handleMessage,
  location_data,
  handleGrid,
}) {
  const [brand] = useBrandInfo();
  const loc_data = localforage.getItem("loc_data");
  const [agency] = useAgencyInfo();
  const { pathname } = useLocation();
  const location_settings = useAgencyInfo()[5];
  var loc_name = pathname.split("/")[4];
  var loc_id = pathname.split("/")[3];
  var loc_add = pathname.split("/")[5];
  var type = pathname.split("/")[2];
  // alert(location_settings.currency_sign);

  const MakePrice = (item) => {
    var totalprice = 0;
    if (item.setup_fee != "") {
      totalprice += parseFloat(item.setup_fee);
    }
    if (item.management_sub != "") {
      totalprice += parseFloat(
        item.management_sub?.substr(0, item.management_sub?.indexOf("("))
      );
    }
    for (let index = 0; index < item.apps.length; index++) {
      const element = item.apps[index];
      var newArray = purchased_apps.filter(function (e) {
        return (
          e.app_id ===
          element.value?.substring(
            element.value?.indexOf("(") + 1,
            element.value?.indexOf(")", element.value?.indexOf("("))
          )
        );
      });
      if (newArray.length) {
        var temp_price = newArray[0].selling_price?.value?.substr(
          0,
          newArray[0].selling_price?.value?.indexOf(" ")
        );
        totalprice += parseFloat(temp_price);
      } else {
        totalprice +=
          parseFloat(element.value?.substr(0, element.value?.indexOf(" "))) * 3;
      }
    }
    return totalprice.toFixed(2);
  };

  return (
    <>
      <Card style={{ marginBottom: "50px" }} className="defeult-theme-card-Bg">
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
          <MDBox
            className="defeult-theme-card"
            variant="gradient"
            bgColor={color}
            color={color === "light" ? "dark" : "white"}
            coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="start"
            alignItems="center"
            width="100%"
            height="4rem"
            mt={-3}
          >
            <MDTypography
              variant="button"
              ml={3}
              fontWeight="bold"
              color="white"
            >
              {category.name}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={2} mt={5} px={2}>
          <Grid container spacing={4}>
            {data.map((item, key) =>
              item.type == "ghlapps" ? (
                <>
                  {item?.category?.findIndex((e) => e.value === category._id) >
                  -1 ? (
                    <Grid item xs={12} md={handleGrid == "grid" ? 3 : 12}>
                      <MDBox mb={3}>
                        <AppDefaultProjectCard
                          handleAlert={handleAlert}
                          image={item.image}
                          title={item.name}
                          data={item}
                          stripe_data={stripe_data}
                          agency_data={loc_data}
                          handlerefresh={"handlerefresh"}
                          handleMessage={"handleMessage"}
                          purchased_data={data}
                          handelchange={handelchange}
                          location_data={"location_data"}
                          handleGrid={"handleGrid"}
                          description={
                            (item?.status?.value == "comming soon" ? (
                              <>
                                <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">
                                    Estimate :
                                  </b>{" "}
                                  {item.timeline.label}
                                </div>
                                <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">
                                    {" "}
                                    Price:
                                  </b>{" "}
                                  {location_settings.currency_sign +
                                    parseFloat(
                                      item.basic.value.substr(
                                        0,
                                        item.basic.value.indexOf(" ")
                                      )
                                    ) *
                                      3}
                                </div>
                                {/* <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">Type:</b>{" "}
                                  {item.app_type?.label}
                                </div> */}
                              </>
                            ) : (
                              <>
                                <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">
                                    Description:
                                  </b>{" "}
                                  {item.description}
                                </div>
                                <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">Type:</b>{" "}
                                  {item.app_type?.label}
                                </div>
                                <div className="snapshot-des-text">
                                  <b className="snapshot-des-heading">Price:</b>{" "}
                                  {/* {location_settings.currency_sign} */}
                                  {item?.configure ? (
                                    <>
                                      {item.selling_price?.value?.substr(
                                        0,
                                        item.selling_price?.value?.indexOf(" ")
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item.basic?.value.substr(
                                        0,
                                        item.basic?.value.indexOf(" ")
                                      ) * 3}
                                    </>
                                  )}
                                </div>
                                <div>
                                  {/* <b>Price:</b> {item.payable=="no"?'Free':'$'+item.purchase_listener.substr(0, item.purchase_listener.indexOf('('))} */}
                                </div>
                              </>
                            )) ||
                            "As Uber works through a huge amount of internal management turmoil."
                          }
                          action={{
                            type: "internal",
                            route: `/store/${type}/${loc_id}/${loc_name}/${loc_add}/agency/${agency._id}/ghlapps/${item._id}/ghlappsDetail`,
                            color: "info",
                            label: "Preview",
                          }}
                          button_label="Buy"
                        />
                      </MDBox>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {type === "snapshot" || type === "service" ? (
                    item.type === type ? (
                      <>
                        {item?.category?.findIndex(
                          (e) => e.value === category._id
                        ) > -1 ? (
                          <Grid item xs={12} md={handleGrid == "grid" ? 3 : 12}>
                            <MDBox mb={3}>
                              <DefaultProjectCard
                                handleAlert={handleAlert}
                                image={item.image_url}
                                title={item.name}
                                purchased_data={data}
                                stripe_data={stripe_data}
                                data={item}
                                agency_data={loc_data}
                                handleMessage={handleMessage}
                                handelchange={handelchange}
                                location_data={location_data}
                                handleGrid={handleGrid}
                                description={
                                  (
                                    <>
                                      <div className="snapshot-des-text">
                                        <b className="snapshot-des-heading">
                                          Description:
                                        </b>{" "}
                                        {item.description}
                                      </div>
                                      <div className="snapshot-des-text">
                                        <b className="snapshot-des-heading">
                                          Type:
                                        </b>{" "}
                                        {item.type}
                                      </div>
                                      <div className="snapshot-des-text">
                                        <b className="snapshot-des-heading">
                                          Price:
                                        </b>{" "}
                                        {item.payable == "no"
                                          ? "Free"
                                          : location_settings.currency_sign +
                                            MakePrice(item)}
                                      </div>
                                    </>
                                  ) ||
                                  "As Uber works through a huge amount of internal management turmoil."
                                }
                                action={{
                                  type: "internal",
                                  route: `/store/${type}/${loc_id}/${loc_name}/${loc_add}/agency/${item.agency_id}/snapshot/${item._id}/SnapshotDetail`,
                                  color: "info",
                                  label: `view ${item.type}`,
                                }}
                                button_label={
                                  item.type == "snapshot"
                                    ? item.payable == "no"
                                      ? "Load"
                                      : "Purchase"
                                    : "Request"
                                }
                              />
                            </MDBox>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )
                  ) : item.type == "bundle" ||
                    item.type == "package" ||
                    item.type == "reselling_bundle" ||
                    item.type == "reselling_service" ? (
                    <>
                      {item?.category?.findIndex(
                        (e) => e.value === category._id
                      ) > -1 ? (
                        <Grid item xs={12} md={handleGrid == "grid" ? 3 : 12}>
                          <MDBox mb={3}>
                            <DefaultProjectCard
                              purchased_apps={purchased_apps}
                              handleAlert={handleAlert}
                              image={item.image_url}
                              title={item.name}
                              stripe_data={stripe_data}
                              data={item}
                              purchased_data={data}
                              agency_data={loc_data}
                              handleMessage={handleMessage}
                              handelchange={handelchange}
                              location_data={location_data}
                              handleGrid={handleGrid}
                              description={
                                (
                                  <>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Description:
                                      </b>{" "}
                                      {item.description}
                                      {/* {item.image_url} */}
                                    </div>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Type:
                                      </b>{" "}
                                      {item.type == "reselling_bundle"
                                        ? "bundle"
                                        : item.type == "reselling_service"
                                        ? "service"
                                        : item.type}
                                    </div>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Price:
                                      </b>{" "}
                                      {location_settings.currency_sign}{" "}
                                      {item.type == "package"
                                        ? item.superadmin_prices.selling_price?.value?.substr(
                                            0,
                                            item.superadmin_prices.selling_price?.value?.indexOf(
                                              " "
                                            )
                                          )
                                        : MakePrice(item)}
                                    </div>
                                  </>
                                ) ||
                                "As Uber works through a huge amount of internal management turmoil."
                              }
                              action={{
                                type: "internal",
                                route: `/store/${type}/${loc_id}/${loc_name}/${loc_add}/agency/${item.agency_id}/snapshot/${item._id}/SnapshotDetail`,
                                color: "info",

                                label: `view ${
                                  item.type == "reselling_bundle"
                                    ? "bundle"
                                    : item.type == "reselling_service"
                                    ? "service"
                                    : item.type
                                }`,
                              }}
                              button_label={"Purchase"}
                            />
                          </MDBox>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {item?.category?.findIndex(
                        (e) => e.value === category._id
                      ) > -1 ? (
                        <Grid item xs={12} md={handleGrid == "grid" ? 3 : 12}>
                          <MDBox mb={3}>
                            <DefaultProjectCard
                              handleAlert={handleAlert}
                              image={item.image_url}
                              title={item.name}
                              data={item}
                              agency_data={loc_data}
                              handleMessage={handleMessage}
                              handelchange={handelchange}
                              stripe_data={stripe_data}
                              location_data={location_data}
                              handleGrid={handleGrid}
                              description={
                                (
                                  <>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Description:
                                      </b>{" "}
                                      {item.description}
                                      {/* {item.image_url} */}
                                    </div>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Type:
                                      </b>{" "}
                                      {item.type}
                                    </div>
                                    <div className="snapshot-des-text">
                                      <b className="snapshot-des-heading">
                                        Price:
                                      </b>{" "}
                                      {item.payable == "no"
                                        ? "Free"
                                        : location_settings.currency_sign +
                                          " " +
                                          MakePrice(item)}
                                    </div>
                                  </>
                                ) ||
                                "As Uber works through a huge amount of internal management turmoil."
                              }
                              action={{
                                type: "internal",
                                route: `/store/${type}/${loc_id}/${loc_name}/${loc_add}/agency/${item.agency_id}/snapshot/${item._id}/SnapshotDetail`,
                                color: "info",
                                label: `view ${item.type}`,
                              }}
                              button_label={
                                item.type == "snapshot"
                                  ? item.payable == "no"
                                    ? "Load"
                                    : "Purchase"
                                  : "Request"
                              }
                            />
                          </MDBox>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )
            )}
          </Grid>
        </MDBox>
      </Card>
    </>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    handleGrid: PropTypes.string,
    data: PropTypes.array,
    category: PropTypes.array,
    agency_data: PropTypes.array,
    location_data: PropTypes.array,
    cat_id: PropTypes.string,
    stripe_data: PropTypes.bool,
    handelchange: PropTypes.func,
    handleMessage: PropTypes.func,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
