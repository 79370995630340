/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import { Grid } from '@mui/material'
import FoegetPassword from './FoegetPassword';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import localforage from 'localforage'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useState } from 'react';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout'
import env from 'config';
// Images
import bgImage from 'assets/images/bg-sign-up-cover.jpeg'
import { useLocation} from 'react-router-dom'

function Login() {
  const [error, seterror] = useState('');
  const [message, setmessage] = useState('');
  let navigate = useNavigate();
const BASE_URL = `${env.API_URL}/v1`
const { pathname } = useLocation()

const handlemessage = async (e,type) => {
  if(type=='true'){
    setmessage(e)
  }else{
    seterror(e)

  }
  setTimeout(() => {
    seterror('')
    setmessage('')
    
  }, 3000);
}
    const handleSubmit = async (e) => {
        e.preventDefault();
  var axios = require('axios');

        const localBrand = await localforage.getItem('loc_data');
    
      
      const email = e.target.email.value;
      const password = e.target.password.value;
      var path = pathname.split('/')[1]
      var loc_name = pathname.split("/")[4].replace(/\s/g, "");
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = pathname.split("/")[5].replace(/\s/g, "");
      
      const data = {
        email: email,
        password: password,
        location_id:localBrand.location_id
      }
      console.log(data, 'data')
      
      axios.post(BASE_URL+'/auth/userlogin', { data }).then(async function (response) {
        if (response.data.success==true) {
          localforage.setItem('brand', response.data.data);
          navigate(
            `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/Snapshot`
          );
        }

      })
        .catch(function (error) {
          seterror(error.response.data.message)
          setTimeout(() => {
            seterror('')
              
            }, 3000);
        });
    }
  return (
    <Grid container mt={3} mb={4} justifyContent="center">
          {
      error!=''?
      (
        <Stack sx={{ width: '90%' }} spacing={2}>
        <Alert severity="error">{error}</Alert>
      </Stack>
      )
      :
      (<></>)
    }
    {message!=''?
    (
      <Stack sx={{ width: '90%' }} spacing={2}>
      <Alert severity="success">{message}</Alert>
    </Stack>
    )
    :
    (<></>)}
    <Grid item xs={6} >
    <MDBox mb={3} mt={5}>
      </MDBox>
      <Card>
        <MDBox
        className="defeult-theme-card"
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={3}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput type="email" label="Email" name="email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" name="password" variant="standard" fullWidth />
            </MDBox>
            <FoegetPassword handlemessage={handlemessage}/>
            <MDBox mt={3} mb={1}>
              <MDButton variant="gradient" type="submit" color="info" fullWidth className="defeult-theme-button ">
                Sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
</Grid>

   
  )
}

export default Login
