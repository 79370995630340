/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// OrderDetails page components
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderDetails({ data, apps_data }) {
  const location_settings = useAgencyInfo()[5];

  console.log(data, apps_data, "OrderDetails");
  var total = apps_data.reduce(
    (total, obj) =>
      obj?.purchased_app?.latest_invoice?.amount_due / 100 + total,
    0
  );
  if (data?.prod?.management_payable == "yes") {
    total += parseFloat(
      data?.prod?.management_sub?.substring(
        0,
        data?.prod?.management_sub?.indexOf("(")
      )
    );
  }
  if (data?.prod?.setup_payable == "yes") {
    total += parseFloat(data?.prod?.setup_fee);
  }
  console.log(total);
  return (
    <MDBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <MDBox pt={1} pb={3} px={3}>
              <MDBox mb={3}>
                {/* <Header data={data}/> */}orderImage
                <OrderInfo data={data} />
              </MDBox>
              <Divider />
              <MDBox mt={3}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={12}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Summary
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  {apps_data.map((item) => (
                    <>
                      <Grid item xs={12} sx={{ ml: "auto" }}>
                        <OrderSummary data={item} />
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12}>
                    <MDBox mb={2}>
                      {data?.prod?.setup_payable == "yes" ? (
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          mb={0.5}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            <b>Setup Fee</b>:
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              {/* ({data?.purchased_app?.sub_id}) */}
                            </span>
                          </MDTypography>
                          <MDBox ml={1}>
                            <MDTypography variant="body2" fontWeight="medium">
                              {location_settings.currency_sign}
                              {parseFloat(data?.prod?.setup_fee)}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      ) : (
                        <></>
                      )}
                      {data?.prod?.management_payable == "yes" ? (
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          mb={0.5}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            <b>Management Subscription</b>:
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              {/* ({data?.purchased_app?.sub_id}) */}
                            </span>
                          </MDTypography>
                          <MDBox ml={1}>
                            <MDTypography variant="body2" fontWeight="medium">
                              {location_settings.currency_sign}
                              {parseFloat(
                                data?.prod?.management_sub.substring(
                                  0,
                                  data?.prod?.management_sub.indexOf("(")
                                )
                              )}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      ) : (
                        <></>
                      )}

                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        mb={0.5}
                        mt={1}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          <b>Total</b>:
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            {/* ({data?.purchased_app?.sub_id}) */}
                          </span>
                        </MDTypography>
                        <MDBox ml={1}>
                          <MDTypography variant="body2" fontWeight="medium">
                            {location_settings.currency_sign}
                            {total}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <PaymentDetails data={data} />
                  </Grid>
                  <Grid item xs={6}>
                    <BillingInformation data={data} />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OrderDetails;
