/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Dashboard from "./dashboard";
import AllAppsIframe from "./Sidenav/Iframs/Iframs.jsx";
// import Tables from './tables'
// import Billing from './billing'
// import Notifications from './notifications'
import Profile from "./profile";
import Snapshot from "./Snapshot/Snapshot";
import Auth from "./Auth";
import Billing from "./setting/components/Card_details/Card_details";
import localforage from "localforage";
import MyApps from "./MyApps";
import Iframs from "./Snapshot/Iframs/Iframs";
import WorkflowApps from "./WorkflowApp/WorkflowApp";
import Widgets from "./Widgets/Widgets";
import AppIfram from "./GhlApps/Iframs/Iframs";
import { useBrandInfo } from "context/brand";
import PurchaseSnapshots from "./PurchaseSnapshots";
import Subscriptions from "./Subscriptions";
import Purchases from "./Purchases";
import Pakages from "./Packages/Pakages";

function useRoutes() {
  const Category = useBrandInfo();
  var cat_route = [];
  for (let index = 0; index < Category[3].length; index++) {
    const element = Category[3][index];
    cat_route.push({
      type: "collapse",
      name: element.name,
      route: "/Snapshot/" + element._id,
      key: element._id,
      component: <Snapshot />,
      sidnavtype: "category",
    });
  }

  const routes = [
    {
      type: "collapse",
      name: "Everything",
      key: "Snapshot",
      route: "/Snapshot",
      component: <Snapshot />,
      sidnavtype: "main",
    },
    {
      type: "collapse",
      name: "Apps",
      key: "apps",
      route: "/apps",
      component: <WorkflowApps />,
      sidnavtype: "main",
    },
    {
      type: "collapse",
      name: "Widget",
      key: "widgets",
      route: "/widgets",
      component: <Widgets />,
      sidnavtype: "main",
    },
    // {
    //   type: "collapse",
    //   name: "Pakages",
    //   key: "pakages",
    //   route: "/pakages",
    //   component: <Pakages />,
    //   sidnavtype: "main",
    // },
    {
      type: "settings",
      name: "Profile",
      key: "profile",
      route: "/settings/profile",
      component: <Profile />,
      sidnavtype: "main",
    },
    {
      type: "settings",
      name: "Payment Method",
      key: "payment",
      route: "/settings/payment",
      component: <Billing />,
      sidnavtype: "main",
    },
    {
      type: "settings",
      name: "Purchases",
      key: "purchases",
      route: "/settings/purchases",
      component: <Purchases />,
      sidnavtype: "main",
    },
    {
      type: "settings",
      name: "My Apps",
      key: "myapps",
      route: "/settings/myapps",
      component: <MyApps />,
      sidnavtype: "main",
    },
    {
      type: "settings",
      name: "Snapshots/Services",
      key: "purchasesnapshots",
      route: "/settings/purchasesnapshots",
      component: <PurchaseSnapshots />,
      sidnavtype: "main",
    },
    {
      type: "settings",
      name: "Subscriptions",
      key: "subscriptions",
      route: "/settings/subscriptions",
      component: <Subscriptions />,
      sidnavtype: "main",
    },
    {
      key: "auth",
      route: "/auth",
      component: <Auth />,
    },
    {
      key: "ghlappsDetail",
      route: "/ghlapps/:id/ghlappsDetail",
      component: <AppIfram />,
    },
    {
      key: "GetAllApps",
      route: "/ghlapps/getallapps",
      component: <AllAppsIframe />,
    },
    {
      key: "snapshotDetail",
      route: "/snapshot/:id/SnapshotDetail",
      component: <Iframs />,
    },
    // {
    //   type      : 'collapse',
    //   name      : 'Notifications',
    //   key       : 'notifications',
    //   icon      : <Icon fontSize="small">notifications</Icon>,
    //   route     : '/notifications',
    //   pathname  : '/admin/dashboard',
    //   component : <Notifications />,
    // },
  ];
  const children = routes.concat(cat_route);
  return children;
}

export default useRoutes;
