import { useAgencyInfo } from "context/agency";
import { useUserInfo } from "context/user";
import localforage from "localforage";
import { useLocation, useNavigate } from "react-router-dom";

function useAuth() {
  const [user] = useUserInfo();
  return user?.token;
}

function useLogout() {
  const { pathname } = useLocation();
  const [agency] = useAgencyInfo();
  var page = decodeURIComponent(pathname.split("/")[1]);
  var type = decodeURIComponent(pathname.split("/")[2]);
  var location_id = decodeURIComponent(pathname.split("/")[3]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const clear = useUserInfo()[2];
  const navigate = useNavigate();

  return () => {
    localforage.removeItem("brand");
    clear();
    navigate(
      `/store/${type}/${location_id}/${loc_name}/${loc_add}/agency/${agency._id}/auth?page=${page}`
    );
  };
}

export { useAuth, useLogout };
