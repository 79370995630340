/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import env from "config";
import { useAppServices } from "hook/services";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import localforage from "localforage";
import { useAgencyInfo } from "context/agency";
export default function SnapshotData() {
  const axios = require("axios");

  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [purchases, setpurchases] = useState([]);
  const [purchased_apps, setpurchased_apps] = useState([]);
  const [category_data, setcategory_data] = useState([]);
  const [snapshot_data, setsnapshot_data] = useState([]);
  const [agency_data, setagency_data] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [location_data, setlocation_data] = useState("");
  const [cat_id, setcat_id] = useState("");
  const [loader, setloader] = useState(true);
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[2]);
  const BASE_URL = `${env.API_URL}/v1`;
  const getApps = async (e) => {
    const { response } = await AppService.app.get();
    if (response) {
      await getAppconfiguration(response.data);
      //   setprocessing(false);
    }
  };
  const StoreView = async (e) => {
    const loc_data = await localforage.getItem("loc_data");
    var data = {
      agency_id: loc_data.agency_id,
      loc_id: loc_data.location_id,
      type: type,
    };

    axios
      .post(BASE_URL + "/storeveiws/submit", { data })
      .then(async function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetCategories = async (e) => {
    var cat_ids = pathname.substring(pathname.indexOf("/Snapshot") + 10);
    setcat_id(cat_ids);
    const loc_data = await localforage.getItem("loc_data");
    axios
      .get(BASE_URL + "/snapshot/category/" + loc_data.agency_id)
      .then(async function (response) {
        var temp_cat = [];
        if (type == "everything") {
          temp_cat = response.data.data;
        } else {
          response.data.data.forEach((el) => {
            el.type.forEach((element) => {
              if (element.value == type) {
                temp_cat.push(el);
              }
            });
          });
        }
        temp_cat.sort((a, b) =>
          a.index > b.index ? 1 : b.index > a.index ? -1 : 0
        );
        setcategory_data(temp_cat);
        // setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetAgencyConfiguration = async (e) => {
    const loc_data = await localforage.getItem("loc_data");

    const agency_datas = await axios
      .get(BASE_URL + "/agency/main_agency/" + loc_data.agency_id)
      .then(async function (response) {
        setagency_data(response.data.data);

        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAppconfiguration = async (apps_data) => {
    const loc_data = await localforage.getItem("loc_data");

    const { response } = await AppService.purchased_apps.filter({
      query: `agency_id=${loc_data.agency_id}`,
    });
    var temp_apps = apps_data;
    if (response) {
      setpurchased_apps(response.data);
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var newArray = temp_apps.filter(function (item) {
          return element.app_id == item.app_id;
        });
        const indexToRemove = temp_apps.findIndex(
          (item) => element.app_id === item.app_id
        );
        if (indexToRemove !== -1) {
          temp_apps.splice(indexToRemove, 1);
        }
        if (newArray[0]) {
          temp_apps.push({
            ...newArray[0],
            price_id: element.price_id,
            configure: true,
            selling_price: element.selling_price || null,
            category: element.category || [],
            type: "ghlapps",
          });
        } else {
          temp_apps.push({
            ...element,
            price_id: element.price_id,
            configure: false,
            selling_price: element.selling_price || null,
            category: element.category || [],
            type: "ghlapps",
          });
        }
      }
      // setpurchases(temp_apps)
      getLocationApps(temp_apps);
    } else {
      getLocationApps(temp_apps);
    }
  };
  const getLocationApps = async (data) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.location_apps.filter({
      query: `locationId=${loc_data?.location_id}`,
    });
    if (response) {
      const temp = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        var newArray = response.data.filter(function (item) {
          return element.app_id == item.app_id;
        });
        if (newArray[0]) {
          temp.push({
            ...element,
            purchased: true,
            ghl: newArray[0]?.ghl || null,
            app_configuration: newArray[0]?.app_configuration,
          });
        } else {
          temp.push({ ...element, purchased: false, ghl: null });
        }
      }
      getSnapshot_data(temp);
      setpurchases(temp);
    } else {
      getSnapshot_data(data);
      setpurchases(data);
    }
  };
  const ghllocation_data = (loc_id, access_token) => {
    var config = {
      method: "get",
      url: "https://services.leadconnectorhq.com/locations/" + loc_id,
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };

    axios(config)
      .then(function (response) {
        setlocation_data(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getSnapshot_data = async (ghlapps) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.location_apps.getLocsnapshots({
      query: `loc_id=${loc_data?.location_id}&agency_id=${loc_data.agency_id}`,
    });
    if (response) {
      console.log(response,'getSnapshot_datagetSnapshot_datagetSnapshot_data')
      const filteredArray = response.data.snapshot.filter(obj => obj.status !== "draft");
      var temp_array = [...filteredArray, ...ghlapps];
      setsnapshot_data(temp_array);
    }
    ghllocation_data(loc_data.location_id, agency?.ghl?.access_token);
    setProcessing(false);
  };
  const onLoad = async () => {
    await getApps();
    StoreView();
    GetCategories();
    GetAgencyConfiguration();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return {
    handleReferesh: onLoad,
    purchases: purchases,
    purchased_apps: purchased_apps,
    snapshot_data: snapshot_data,
    agency_data: agency_data,
    location_data: location_data,
    category_data: category_data,
    processing: processing,
  };
}
