import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "qs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ReqMethods, ghl } from "enums";
import env from "config";
import { useAppServices } from "hook/services";
import { useBrandInfo } from "context/brand";
import localforage from "localforage";
// import { AgencyContext } from '../../../context/Agency.context'

function GHL() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const code = new URLSearchParams(search).get("code");
  const state_data = new URLSearchParams(search).get("state");
  const AppServices = useAppServices();
  const [Error, setError] = useState("");
  const [brand, update] = useBrandInfo();
  const app_id = state_data.split(",")[0];
  const locationId = state_data.split(",")[1];
  const agency_id = state_data.split(",")[2];
  const type = state_data.split(",")[3];
  const associate = async ({ location }, creds) => {
    const loc_data = await localforage.getItem("loc_data");
    const payload = {
      app_id: app_id,
      locationId: locationId,
      agency_id: agency_id,
      type: type,
      ghl: {
        location_id: creds.locationId,
        company_id: location.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
      },
    };

    const { response } = await AppServices.location_apps.update({ payload });
    console.log(payload, "payload");
    if (!response)
      return setError(
        "Something went wrong while in associate integration, please try latter"
      );
    return navigate(-2);
  };

  const getLocation = async (creds) => {
    const payload = {
      method: ReqMethods.GET,
      path: `${ghl.APIs.v2.location(creds.locationId)}`,
      key: creds.access_token,
      refresh_token: creds.refresh_token,
    };
    // console.log(payload)
    const { response } = await AppServices.services.ghl.call_service({
      payload,
    });

    if (!response)
      return setError(
        "Something went wrong while integration, please try latter"
      );
    // console.log(response.data)

    return response && associate(response.data, creds);
  };
  const getCreds = async (settings) => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      payload: QueryString.stringify({
        client_id: settings.client_id,
        client_secret: settings.client_secret,
        grant_type: "authorization_code",
        code,
        refresh_token: "",
      }),
    };
    console.log(payload, "getCreds");

    const { response } = await AppServices.services.ghl.call_service({
      payload,
    });

    if (!response)
      return setError(
        "Something went wrong while in Cred integration, please try latter"
      );
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')

    return response && getLocation(response.data);
  };
  const getSettings = async (e) => {
    const { response } = await AppServices.app.filter({
      query: `app_id=${app_id}`,
    });
    console.log(response, "response");

    if (response) {
      getCreds(response.data[0]);
    }
    return setError(
      "Something went wrong while in settings integration, please try latter"
    );
  };

  const onLoad = () => {
    getSettings();
  };

  useEffect(onLoad, []);

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default GHL;
