/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate } from 'react-router-dom'
import localforage from 'localforage'
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import { Grid } from '@mui/material'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useState } from 'react'
import MuiPhoneNumber from 'material-ui-phone-number';
// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout'

// Images

import bgImage from 'assets/images/bg-sign-up-cover.jpeg'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import env from 'config'
import { useLocation} from 'react-router-dom'

function Register() {
  let navigate = useNavigate();

  const [error, seterror] = useState('');
  const [message, setmessage] = useState('');
  const BASE_URL = `${env.API_URL}/v1`
  const { pathname } = useLocation()

   const handleOnChange=(value) =>{
        // alert(value);
        // console.log(value)
     }
    const handleSubmit = async (e) => {
        e.preventDefault();
  var axios = require('axios');

    const localBrand = await localforage.getItem('loc_data');

    
      
      const username = e.target.username.value;
      const email = e.target.email.value;
      const password = e.target.password.value;
      const phone = e.target.phone.value;

      var path = pathname.split('/')[1]
      var loc_name = pathname.split("/")[4].replace(/\s/g, "");
      var type = decodeURIComponent(pathname.split("/")[2]);
      var loc_add = pathname.split("/")[5].replace(/\s/g, "");
      const data = {
        username: username,
        email: email,
        phone:phone,
        password:password

      }
      if(username==''|| email==''|| phone==''){
        seterror('please fill the all fields')
        setTimeout(() => {
          seterror('')
            
          }, 3000);
      }else{
        console.log(data, 'data')

        axios.post(BASE_URL+'/auth/user/'+localBrand.agency_id, { data }).then(async function (response) {
          console.log(response);
          if (response.data.success==true) {
            localforage.setItem('brand', response.data.data);
            navigate(
              `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/Snapshot`
            );
          }
          setmessage('Registered Successfully')
          setTimeout(() => {
          setmessage('')
            
          }, 3000);
  
        })
          .catch(function (error) {
            console.log(error);
          seterror(error.response.data.message)
          setTimeout(() => {
            seterror('')
              
            }, 3000);
  
          });
      }
    }
  return (
    <Grid container mt={3} justifyContent="center">
        {
      error!=''?
      (
        <Stack sx={{ width: '90%' }} spacing={2}>
        <Alert severity="error">{error}</Alert>
      </Stack>
      )
      :
      (<></>)
    }
    {
     message!=''?
      (
        <Stack sx={{ width: '90%' }} spacing={2}>
        <Alert severity="success">{message}</Alert>
      </Stack>
       )
      :
      (<></>)
    } 
    <Grid item xs={6} >
    <MDBox mb={3} mt={5}>
      </MDBox>
      <Card>
        <MDBox
        className="defeult-theme-card"
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="text" label="Name" name="username" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
            <MuiPhoneNumber defaultCountry={'us'} name="phone" fullWidth onChange={handleOnChange}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" name="email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="password" name="password" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" type="submit" color="info" fullWidth className="defeult-theme-button ">
                Sign Up
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
</Grid>

   
  )
}

export default Register
