import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import localforage from "localforage";
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import MDButton from "components/MDButton";
import PurchasesDetailsModal from "./components/PurchasesDetailsModal";
import IntegrationModal from "./components/IntegrationModal";
import env from "config";
import SubscriptionModal from "./components/SubscriptionModal";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal";
import ReSubscriptionModal from "./components/ReSubscriptionModal";
import { RemoveRedEye } from "@mui/icons-material";
import ConfirmAlert from "./components/ConfirmAlert";
import { useAgencyInfo } from "context/agency";

function Default() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [processing, setprocessing] = useState(true);
  const [Conjo, setConjo] = useState([]);
  const [sessionkey, setsessionkey] = useState();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);
  var locationId = decodeURIComponent(pathname.split("/")[3]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const location_settings = useAgencyInfo()[5];

  const handleAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 4000);
  };
  const getApps = async (e) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.location_apps.getmyapps({
      query: `locationId=${loc_data.location_id}& agency_id=${loc_data.agency_id}`,
    });
    if (response) {
      const sessionKey = await localforage.getItem("sessionkey");
      await makeData(response.data, sessionKey);
      setprocessing(false);
    } else {
      setprocessing(false);
    }
  };
  const makeData = async (data, sessionKey) => {
    const tabel_data = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      console.log(element, "element");
      const temp = [
        element.app_data?.name,
        element.app_data?.app_type?.value,
        element.location_app?.status,
        location_settings.currency_sign +
          parseInt(element.location_app?.latest_invoice?.total) / 100,
        <>
          {sessionKey == undefined || sessionKey == null || sessionKey == "" ? (
            <MDButton
              onClick={handleAlert}
              variant="contained"
              color={element.location_app?.ghl ? "success" : "info"}
              size="small"
            >
              {element.location_app?.ghl ? "Connected" : "Connect"}
            </MDButton>
          ) : (
            <MDButton
              variant="contained"
              color={element.location_app?.ghl ? "success" : "info"}
              size="small"
            >
              <a
                href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.LOCATION}&client_id=${element.app_data.client_id}&scope=${env.GHL.SCOPE}&state=${element.app_data.app_id},${locationId},${agency_id}&sessionKey=${sessionKey}`}
                style={{ color: "inherit" }}
              >
                {element.location_app?.ghl ? "Connected" : "Connect"}
              </a>
            </MDButton>
          )}
          {element.app_data?.integration_type == "api_key" ? (
            <IntegrationModal
              data={element.location_app}
              handleRefresh={onLoad}
            />
          ) : (
            <></>
          )}
          {/* {element.location_app.status == "incomplete" ? (
            <SubscriptionModal data={element} handleRefresh={onLoad} />
          ) : element.location_app.status == "cancel" ? (
            <ReSubscriptionModal data={element} handleRefresh={onLoad} />
          ) : (
            <CancelSubscriptionModal data={element} handleRefresh={onLoad} />
          )} */}
        </>,
        <PurchasesDetailsModal data={element} />,
      ];
      tabel_data.push(temp);
    }
    setConjo(tabel_data);
  };
  const onLoad = async () => {
    // const loc_data = await localforage.getItem("sessionkey");
    // setsessionkey(loc_data);
    // // alert(loc_data);
    getApps();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Invoice",
    //   options: {
    //     filter: false,
    //   },
    // },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  return (
    <>
      <DashboardLayout>
        <ConfirmAlert
          handleSubmit={handleAlert}
          state={state}
          text={"Your Session Key is Missing Please Refresh Your Page !"}
        />
        {processing ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable data={Conjo} columns={columns} options={options} />
            </Grid>
          </Grid>
        )}
      </DashboardLayout>
    </>
  );
}

export default Default;
