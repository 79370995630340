import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import { Grid } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import React, { useState, useEffect } from "react";
import localforage from "localforage";
import IntegrationModal from "./IntegrationModal";
import env from "config";
import { useUserInfo } from "context/user";
import { useAppServices } from "hook/services";
function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
  button_label,
  handleAlert,
  button_url,
  handleChange,
  handlerefresh,
  data,
  votes,
  agency_data,
  handleMessage,
  handelchange,
  location_data,
  handleGrid,
}) {
  const [user] = useUserInfo();
  var axios = require("axios");
  const AppService = useAppServices();
  const BASE_URL = `${env.API_URL}/v1`;
  const [sessionkey, setsessionkey] = useState();
  const { pathname } = useLocation();
  var loc_name = pathname.split("/")[4].replace(/\s/g, "");
  var type = decodeURIComponent(pathname.split("/")[2]);
  var locationId = decodeURIComponent(pathname.split("/")[3]);
  var agency_id = decodeURIComponent(pathname.split("/")[7]);
  var loc_add = pathname.split("/")[5].replace(/\s/g, "");
  const navigate = useNavigate();
  useEffect(async () => {
    // alert(data.purchased);
    const loc_data = await localforage.getItem("sessionkey");
    setsessionkey(loc_data);
    // alert(button_label)
  }, []);
  const handleVote = async () => {
    const user_data = await localforage.getItem("brand");
    const loc_data = await localforage.getItem("loc_data");
    if (user_data == undefined) {
      alert("For Vote a App make Sure You Must be Login!");
      navigate(
        `/store/${type}/${loc_data.location_id}/${loc_name}/${loc_add}/agency/${loc_data.agency_id}/settings`
      );
    } else {
      const payload = {
        agency_id: loc_data.agency_id,
        location_id: loc_data.location_id,
        user_id: user_data._id,
        app_id: data._id,
      };
      console.log(payload, "payload");
      const { response } = await AppService.vote.create({
        payload: payload,
      });
      if (response) {
        handlerefresh();
      }
    }
  };
  const handlebuy = async () => {
    const loc_data = await localforage.getItem("loc_data");

    const snapshot = data;
    console.log(snapshot);
    axios
      .post(
        BASE_URL +
          "/snapshot/create_snapshot/load/" +
          loc_data.location_id +
          "/" +
          loc_data.agency_id,
        { snapshot }
      )
      .then(async function (response) {
        console.log(response.data);
        handleMessage(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      {/* {
        message !='' ?
        ( */}
      {/* )
        :
        (<></>)
      } */}

      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      className="defeult-theme-card-Bg"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {handleGrid == "list" ? (
        <MDBox
          position="relative"
          width="100.25%"
          shadow="xl"
          borderRadius="xl"
        >
          <Grid container spacing={8} mb={4}>
            <Grid
              item
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CardMedia
                src={image}
                component="img"
                title={title}
                sx={{
                  maxWidth: "100%",
                  margin: 0,
                  boxShadow: ({ boxShadows: { md } }) => md,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>
            <Grid item md={8}>
              <MDBox mt={1} mx={0.5}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  {label}
                </MDTypography>
                <MDBox mb={1}>
                  {action.type === "internal" ? (
                    <MDTypography
                      onClick={() =>
                        navigate(`..${action.route}`, { state: data })
                      }
                      variant="h5"
                      textTransform="capitalize"
                      className="snapshot-title"
                    >
                      {title}
                    </MDTypography>
                  ) : (
                    <MDTypography
                      component="a"
                      href={action.route}
                      target="_blank"
                      rel="noreferrer"
                      variant="h5"
                      textTransform="capitalize"
                      className="snapshot-title"
                    >
                      {title}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox mb={3} lineHeight={0}>
                  <MDTypography
                    variant="button"
                    fontWeight="light"
                    color="text"
                  >
                    {description}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="" alignItems="center">
                  {action.type === "internal" ? (
                    <>
                      <MDButton
                        sx={{ marginRight: "8px" }}
                        variant="outlined"
                        size="small"
                        component={Link}
                        to={action.route}
                        color="info"
                        className="snapshot-btn"
                      >
                        {action.label}
                      </MDButton>
                      {data?.purchased ? (
                        <MDButton
                          variant="outlined"
                          size="small"
                          color="success"
                          disabled
                        >
                          {"Loaded"}
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="outlined"
                          size="small"
                          color="success"
                        >
                          Buy
                        </MDButton>
                        // <BuyModal
                        //   data={data}
                        //   label={button_label}
                        //   handleMessage={handleMessage}
                        //   handelchange={handelchange}
                        //   location_data={location_data}
                        // />
                      )}
                    </>
                  ) : (
                    <MDButton
                      component="a"
                      href={action.route}
                      target="_blank"
                      rel="noreferrer"
                      variant="outlined"
                      size="small"
                      color={action.color}
                    >
                      {action.label}
                    </MDButton>
                  )}
                  <MDBox display="flex">{renderAuthors}</MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <>
          <MDBox
            position="relative"
            width="100.25%"
            shadow="xl"
            borderRadius="xl"
          >
            <CardMedia
              src={image}
              component="img"
              title={title}
              sx={{
                maxWidth: "100%",
                margin: 0,
                boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </MDBox>
          <MDBox mt={1} mx={0.5}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              {label}
            </MDTypography>
            <MDBox mb={1}>
              {action.type === "internal" ? (
                <MDTypography
                  component={Link}
                  to={action.route}
                  variant="h5"
                  textTransform="capitalize"
                  className="snapshot-title"
                >
                  {title}
                </MDTypography>
              ) : (
                <MDTypography
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  textTransform="capitalize"
                  className="snapshot-title"
                >
                  {title}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={3} lineHeight={0}>
              <MDTypography variant="button" fontWeight="light" color="text">
                {description}
              </MDTypography>
            </MDBox>
            {data.status.value == "comming soon" ? (
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDButton
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={action.route}
                    color="info"
                    className="snapshot-btn"
                  >
                    {action.label}
                  </MDButton>
                  <MDButton
                    className="snapshot-btn"
                    variant="outlined"
                    size="small"
                    color="info"
                  >
                    Comming Soon
                  </MDButton>
                </MDBox>
                {votes?.findIndex((e) => e.app_id === data._id) > -1 ? (
                  <></>
                ) : (
                  <MDButton
                    sx={{ marginTop: "0.7rem" }}
                    variant="outlined"
                    className="snapshot-btn"
                    size="small"
                    color="info"
                    onClick={handleVote}
                    fullWidth
                  >
                    <MDBox
                      className="snapshot-btn"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      VOTE THIS APP UP!
                      <MDTypography
                        sx={{ fontSize: "13px" }}
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        click me to get this app sooner
                      </MDTypography>
                    </MDBox>
                  </MDButton>
                )}
              </MDBox>
            ) : (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {action.type === "internal" ? (
                  <>
                    <MDButton
                      variant="outlined"
                      className="snapshot-btn"
                      color="info"
                      size="small"
                    >
                      <a
                        href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.LOCATION}&client_id=${data.client_id}&scope=${env.GHL.SCOPE}&state=${data.app_id},${locationId},${agency_id}&sessionKey=${sessionkey}`}
                        style={{ color: "inherit" }}
                      >
                        {data?.ghl ? "Connected" : "Connect"}
                      </a>
                    </MDButton>
                  </>
                ) : (
                  <MDButton
                    component="a"
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    size="small"
                    color={action.color}
                  >
                    {action.label}
                  </MDButton>
                )}
                <MDBox display="flex">{renderAuthors}</MDBox>
              </MDBox>
            )}
          </MDBox>
        </>
      )}
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    handleGrid: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    agency_data: PropTypes.array,
    location_data: PropTypes.array,
    handleChange: PropTypes.func,
    handleMessage: PropTypes.func,
    handelchange: PropTypes.func,
    handlerefresh: PropTypes.func,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
