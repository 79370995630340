/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples

import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// card page components
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import PaymentMethod from "../PaymentMethod";
import Invoices from "../Invoice";
import env from "config";
import Alert from "@mui/material/Alert";
import localforage from "localforage";
import Stack from "@mui/material/Stack";
import Loader from "examples/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/theme1/DashboardNavbar";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
// import cardInformation from './components/cardInformation'
// import Transactions from './components/Transactions'

function Card_details() {
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [customer, setCustomer] = useState({});
  const [total_locations, setTotalLocations] = useState(0);
  const [primaryCard, setPrimaryCard] = useState({});
  const [message, setmessage] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [loader, setloader] = useState(true);
  const [errormsg, seterrormsg] = useState("");
  const [stripe_data, setstripe_data] = useState("");
  console.log(subscriptions);
  const BASE_URL = `${env.API_URL}/v1`;
  const handleRefresh = () => {
    // alert("sjdfgh");
    setrefresh(!refresh);
  };
  const handlemessage = (e, type) => {
    // alert(type)
    if (type == "success") {
      setmessage(e);
    } else {
      seterrormsg(e);
    }
    setTimeout(() => {
      seterrormsg("");
      setmessage("");
    }, 3000);
    onLoad();
  };
  var axios = require("axios");
  // const getBilling = async () => {
  //   const agency = await localforage.getItem("brand");

  //   // alert(agency.stripe?.user_id)
  //   axios
  //     .get(
  //       BASE_URL +
  //         "/snapshot/stripe/invoices/" +
  //         agency.agency_id +
  //         "/" +
  //         agency._id
  //     )
  //     .then(async function (response) {
  //       console.log(response.data, "invoices");
  //       setInvoices(response.data.data.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   // const { response } = await AppService.agency.billing({
  //   //   query: `customer_id=${agency.stripe?.customer_id}`,
  //   // })

  //   // response && setInvoices(response.data)
  // };
  const getStripeData = async () => {
    const localBrand = await localforage.getItem("brand");
    const { response } = await AppService.stripe.filter({
      id:localBrand._id
    });
    console.log(response,"getStripeData")
    if (response) {
      setstripe_data(response.data);
      setloader(false);
    }else{
      setloader(false);
    }
    // await axios
    //   .get(BASE_URL + "/snapshot/stripe/filter/" + localBrand._id)
    //   .then(async function (response) {
    //     setstripe_data(response.data.data);
    //     console.log(response.data, "card");
    //     setloader(false);
    //   })
    //   .catch(function (error) {
    //     setloader(false);
    //     console.log(error);
    //   });
  };

  const getPaymentMethods = async () => {
    const { response } = await AppService.agency.get_payment_methods({
      query: `customer_id=${agency.stripe?.customer_id}`,
    });

    const getPrimaryCard = () =>
      response.data.find(
        (item) =>
          item.id == response.customer.invoice_settings?.default_payment_method
      );

    if (response) {
      setPaymentMethods(response.data);
      setPrimaryCard(getPrimaryCard());
      setCustomer(response.customer);
    }
  };

  const getSubscriptions = async () => {
    const { response } = await AppService.agency.subscriptions({
      query: `_id=${agency._id}`,
    });

    if (response) {
      setSubscriptions(response.data.subscriptions);
      setTotalLocations(response.data.total_locations);
    }
  };

  const onLoad = async () => {
    getPaymentMethods();
    getSubscriptions();
    // await getBilling();
    getStripeData();
  };

  useEffect(onLoad, [refresh]);

  console.log(primaryCard);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6}>
        {loader ? (
          <Loader />
        ) : (
          <MDBox mb={3}>
            <Card
              sx={{
                position: "relative",
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <MDBox
                className="defeult-theme-card"
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Payment Method
                </MDTypography>
              </MDBox>
              <MDBox mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      {errormsg != "" ? (
                        <Stack sx={{ width: "90%" }} spacing={2}>
                          <Alert severity="error">{errormsg}</Alert>
                        </Stack>
                      ) : (
                        <></>
                      )}
                      {message != "" ? (
                        <Stack sx={{ width: "90%" }} spacing={2}>
                          <Alert severity="success">{message}</Alert>
                        </Stack>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={12}>
                        <MDBox maxWidth={350} mx={8}>
                          {stripe_data == "" ? (
                            <MasterCard
                              number={`****************`}
                              holder={"N/A"}
                              expires={"**" / "**"}
                            />
                          ) : (
                            <MasterCard
                              number={`************${
                                stripe_data.card[0]?.last4 || "****"
                              }`}
                              holder={stripe_data.name}
                              expires={`${
                                stripe_data.card[0]?.exp_month || "**"
                              }/${
                                stripe_data.card[0]?.exp_year
                                  .toString()
                                  .substr(2) || "**"
                              }`}
                            />
                          )}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={10} lg={8} xl={6}>
                        <PaymentMethod
                          paymentMethodState={{
                            paymentMethods,
                            setPaymentMethods,
                          }}
                          customerState={{ customer, setCustomer }}
                          stripe_data={stripe_data}
                          handlemessage={handlemessage}
                          handleRefresh={handleRefresh}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} lg={4}>
                <Invoices invoices={invoices} />
              </Grid> */}
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Card_details;
