import { Card, Divider, Grid, IconButton, Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import Header from './components/Header'
import DefulatSettings from './components/DefulatSettings'
import Integration from './components/Integration'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../DashboardNavbar'
import { useState, useEffect } from 'react'
import localforage from 'localforage'
import Profile from './components/Profile'
import Card_details from './components/Card_details/Card_details'
import Login from './components/Login'
import env from 'config'
import Register from './components/Register'
import useRoutes from '../routes'

function Settings() {
  const [brand, setbrand] = useState([])
  const routes = useRoutes()

const BASE_URL = `${env.API_URL}/v1`

  useEffect(async() => {
    const localBrand = await localforage.getItem('brand');
    setbrand(localBrand)

  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar routes={routes}/>
        <Grid container mt={10}>
          <Grid item xs={12}>
            {
              brand!=null?
              (
                <>
            <Header elements={[<Card_details/>,<Profile/>]} />
                
                </>
              )
              :
              (
                <>
            <Header elements={[<Login/>,<Register/>]} />
                
                </>
              )
            }
          </Grid>
        </Grid>
    </DashboardLayout>
  )
}

export default Settings