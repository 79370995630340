import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import { useAppServices } from "hook/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";

function Default() {
  const AppService = useAppServices();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const searchParams = new URLSearchParams(document.location.search);
  const [sessionkey, setsessionkey] = useState(searchParams.get("sessionkey"));
  const getApps = async (e) => {
    alert("getApps");
  };
  const onLoad = async () => {
    getApps();
  };

  useEffect(async () => {
    onLoad();
  }, []);
  return <MDBox>test</MDBox>;
}

export default Default;
