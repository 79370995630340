import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../DashboardNavbar";
import React from "react";
import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import { Grid } from "@mui/material";
import { Card } from "@material-ui/core";
import moment from "moment";
import Loader from "examples/Loader";
import { useEffect, useState } from "react";
import env from "config";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "./components/DefaultProjectCard";
import { useAppServices } from "hook/services";
import { useUserInfo } from "context/user";
import localforage from "localforage";
import ConfirmAlert from "./components/ConfirmAlert";
import useRoutes from "../routes";
import { useAgencyInfo } from "context/agency";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import BuyAllAppModal from "./components/BuyAllAppModal";

function Workflow() {
  const AppService = useAppServices();
  const [user] = useUserInfo();
  const [agency] = useAgencyInfo();
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [purchases, setpurchases] = useState([]);
  const [Apps, setApps] = useState([]);
  const [categories, setcategories] = useState([]);
  const [votes, setvotes] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [processing, setprocessing] = useState(true);
  const [start_num, setstart_num] = React.useState("");
  const [text, settext] = React.useState("");
  const [end_num, setend_num] = React.useState("");
  const [handleGrid, sethandleGrid] = useState("grid");
  const color = "info";
  const { pathname } = useLocation();
  const routes = useRoutes();
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_name = decodeURIComponent(pathname.split("/")[4]);
  var loc_add = decodeURIComponent(pathname.split("/")[5]);
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const iframeStyles = {
    width: "102.5%",
    height: "calc(100vh - 100px)",
    marginLeft: "-17px",
    marginBottom: "-27px",
    border: "none",
  };
  const epoch = moment.unix(1581292800);
  const BASE_URL = `${env.API_URL}/v1`;
  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const getCategories = async (e) => {
    var axios = require("axios");
    const { response } = await AppService.categories.get({
      query: "created_by=superadmin",
    });
    if (response) {
      var temp_cat = [];
      // temp_cat = response.data;
      // const temp = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var newArray = element.type.filter(function (item) {
          return item.value == "app";
        });
        if (newArray.length) {
          temp_cat.push(element);
        }
      }
      temp_cat.sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
      setcategories(temp_cat);
    }
    // axios.get(BASE_URL + "/snapshot/category/" + agency._id)
    // .then(async function (response) {
    //   // console.log(response.data, "category");
    //   // alert(type)
    //   var temp_cat = [];
    //   if (type == "everything") {
    //     temp_cat = response.data.data;
    //   } else {
    //     response.data.data.forEach((el) => {
    //       el.type.forEach((element) => {
    //         if (element.value == type) {
    //           temp_cat.push(el);
    //         }
    //       });
    //     });
    //   }
    //   temp_cat.sort((a, b) =>
    //     a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    //   );
    //   setcategories(temp_cat);
    //   // console.log(temp_cat, "temp_cat");
    //   // setProcessing(false);
    // })
    // .catch(function (error) {
    //   // console.log(error);
    // });
  };
  const getVotes = async (e) => {
    const user_data = await localforage.getItem("brand");
    const { response } = await AppService.vote.filter({
      query: `user_id=${user_data._id}`,
    });
    if (response) {
      setvotes(response.data);
    } else {
      setvotes([]);
    }
  };
  const getApps = async (e) => {
    const { response } = await AppService.app.get();
    if (response) {
      setApps(response.data);
      await getAppconfiguration(response.data);
      setprocessing(false);
    } else {
      setApps([]);
      setprocessing(false);
    }
  };
  const getAppconfiguration = async (apps_data) => {
    const loc_data = await localforage.getItem("loc_data");

    const { response } = await AppService.purchased_apps.filter({
      query: `agency_id=${loc_data.agency_id}`,
    });
    var temp_apps = apps_data;
    if (response) {
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var newArray = temp_apps.filter(function (item) {
          return element.app_id == item.app_id;
        });
        const indexToRemove = temp_apps.findIndex(
          (item) => element.app_id === item.app_id
        );

        if (indexToRemove !== -1) {
          temp_apps.splice(indexToRemove, 1);
        }
        if (newArray[0]) {
          temp_apps.push({
            ...newArray[0],
            price_id: element.price_id,
            configure: true,
            selling_price: element.selling_price || null,
          });
        } else {
          temp_apps.push({
            ...element,
            price_id: element.price_id,
            configure: false,
            selling_price: element.selling_price || null,
          });
        }
      }

      // setpurchases(temp_apps)
      getLocationApps(temp_apps);
    } else {
      getLocationApps(temp_apps);
    }
  };
  const getLocationApps = async (data) => {
    const loc_data = await localforage.getItem("loc_data");
    const { response } = await AppService.location_apps.filter({
      query: `locationId=${loc_data?.location_id}`,
    });
    console.log(response, "getLocationApps");
    if (response) {
      const temp = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        var newArray = response.data.filter(function (item) {
          return element.app_id == item.app_id;
        });

        if (newArray[0]) {
          temp.push({
            ...element,
            purchased: true,
            app_configuration: newArray[0]?.app_configuration,
            ghl: newArray[0]?.ghl || null,
          });
        } else {
          temp.push({ ...element, purchased: false, ghl: null });
        }
      }

      setpurchases(temp);
    } else {
      setpurchases(data);
    }
  };
  const onLoad = () => {
    getVotes();
    getCategories();
    getApps();
  };
  const time = moment.unix(epoch);

  useEffect(async () => {
    onLoad();
  }, [refresh]);
  const handleview = (type) => {
    // alert(type)
    if (type == "grid") {
      sethandleGrid("grid");
    } else {
      sethandleGrid("list");
    }
  };
  const handlerange = (start_num, end_num) => {
    setstart_num(start_num);
    setend_num(end_num);
  };
  const handleAlert = async (text, alert_type) => {
    const localBrand = await localforage.getItem("loc_data");
    setState({ ...state, open: true });
    settext(text);
    // alert(alert_type)
    if (alert_type == "auth") {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/auth`
        );
      }, 2000);
    } else if (alert_type == "session key") {
      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      setTimeout(() => {
        navigate(
          `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/setting
s/payment`
        );
      }, 2000);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar
          routes={routes}
          handleview={handleview}
          handlerange={handlerange}
          handelrefresh={() => setrefresh(!refresh)}
        />
        <ConfirmAlert handleSubmit={handleAlert} state={state} text={text} />
        {processing ? (
          <Loader />
        ) : (
          <>
            {/* <Grid item xs={12} sx={{ textAlign: "right" }} mt={4}>
              <MDBox mb={3}>
                <BuyAllAppModal
                  handleAlert={handleAlert}
                  handlerefresh={onLoad}
                />
              </MDBox>
            </Grid> */}
            <ComplexStatisticsCard
              handleAlert={handleAlert}
              Apps={Apps}
              votes={votes}
              purchases={purchases}
              category={categories}
              handlerefresh={onLoad}
            />
          </>
        )}
      </DashboardLayout>
    </>
  );
}

export default Workflow;
