import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppServices } from "hook/services";
import { useUserInfo } from "context/user";
import { useAgencyInfo } from "context/agency";
import "../../Snapshot/components/style.css";

function AddProduct({ open, onClose, data, handleRefresh }) {
  const [processing, setProcessing] = useState(false);
  const location_settings = useAgencyInfo()[5];
  const [agency] = useAgencyInfo();
  const AppService = useAppServices();
  const handleSubmit = async () => {
    // alert(data.invoice.payment_intent.client_secret)
    setProcessing(true);

    const payload = {
      _id: data.purchased_app_id,
      type: data.type,
      sub_id: data.sub_id,
      agency_id: agency._id,
      status: "cancel",
    };
    console.log(payload, "payload");
    const { response } = await AppService.location_apps.cancelsubscription({
      payload,
    });
    if (response) {
      console.log(response, "response");
      setProcessing(false);
      handleRefresh();
      onClose();
    }
  };
  return (
    <MDModal open={open} onClose={onClose} className={"buy-modal"}>
      <div>
        <form className="form">
          <div className="banner"></div>
          <label className="title">Get Your Subscription Cancel</label>
          <label className="title-main">{data.name}</label>

          <div className="benefits">
            <span>Description</span>
            <ul>
              <li>
                <span> {"data.description"}</span>
              </li>
            </ul>
          </div>

          <div class="modal--footer">
            <label class="price">
              <sup>{location_settings.currency_sign}</sup>
              {data.invoice?.total / 100}
            </label>
            <MDButton
              loading={processing}
              disabled={processing}
              class="upgrade-btn"
              type="button"
              onClick={handleSubmit}
            >
              Confirm to Cancel
            </MDButton>
          </div>
        </form>
      </div>
    </MDModal>
  );
}
const Default = ({ data, handleRefresh }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  return (
    <>
      <MDButton
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color={"info"}
        onClick={() => setOpenAddProduct(true)}
        size="small"
      >
        Cancel Subscription
      </MDButton>
      <AddProduct
        handleRefresh={handleRefresh}
        open={openAddProduct}
        onClose={() => setOpenAddProduct(false)}
        data={data}
      />
    </>
  );
};

export default Default;
