import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useRef } from "react";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import { useNavigate, useLocation } from "react-router-dom";
import env from "config";
import ConfirmAlert from "./ConfirmAlert";
import "./style.css";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";

const BuyModal = (props) => {
  const AppService=useAppServices()
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [brand, setbrand] = useState([]);
  const [stripe_data, setstripe_data] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;
  const { pathname } = useLocation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const location_settings = useAgencyInfo()[5];
  const inputRef = useRef();
  var path = pathname.split("/")[2];
  var loc_name = decodeURIComponent(pathname.split("/")[4].replace(/\s/g, ""));
  var loc_id = decodeURIComponent(pathname.split("/")[3].replace(/\s/g, ""));
  var type = decodeURIComponent(pathname.split("/")[2]);
  var loc_add = decodeURIComponent(pathname.split("/")[5].replace(/\s/g, ""));
  var axios = require("axios");
  let navigate = useNavigate();

  const varifyApp = (id) => {
    console.log(props.purchased_data, "purchased_data");
    if (props.purchased_data.length > 0) {
      let obj = props.purchased_data.find((o) => o.app_id === id);
      if (
        obj?.purchased ||
        obj?.status == "succeeded" ||
        obj?.status == "active"
      ) {
        return "(Purchased)";
      }
    }
  };
  const MakePrice = (item) => {
    console.log(props, "itemitem");
    var totalprice = 0;
    if (item.setup_fee != "" && item.setup_fee != undefined) {
      totalprice += parseFloat(item.setup_fee);
    }
    if (item.management_sub != "") {
      totalprice += parseFloat(
        item.management_sub.substr(0, item.management_sub.indexOf("("))
      );
    }
    for (let index = 0; index < item.apps.length; index++) {
      const element = item.apps[index];
      var newArray = props.purchased_apps.filter(function (e) {
        return (
          e.app_id ===
          element.value.substring(
            element.value.indexOf("(") + 1,
            element.value.indexOf(")", element.value.indexOf("("))
          )
        );
      });
      console.log(newArray);
      if (newArray.length) {
        var varify = varifyApp(newArray[0].app_id);
        if (varify != "(Purchased)") {
          var temp_price = newArray[0].selling_price?.value.substr(
            0,
            newArray[0].selling_price?.value.indexOf(" ")
          );
          totalprice += parseFloat(temp_price);
        }
      } else {
        totalprice +=
          parseFloat(element.value.substr(0, element.value.indexOf(" "))) * 3;
      }
    }
    return totalprice.toFixed(2);
  };
  const handlemodal = async (newState) => {
    const brand_data = await localforage.getItem("brand");
    if (brand_data == null) {
      const localBrand = await localforage.getItem("loc_data");
      props.handleAlert(
        "for purchase a Bundle make sure you must be login!",
        "auth"
      );
    } else {
      setOpenAddProduct(true);
    }
  };
  const getStripe = async (e) => {
    const user_data = await localforage.getItem("brand");
    const { response } = await AppService.stripe.filter({
      id: user_data._id,
    });
    if (response) {
      setstripe_data(true);

    } else {
      setstripe_data(false);

    }
  };
  useEffect(async () => {
    const brand_data = await localforage.getItem("brand");
    // alert(brand_data)
    setbrand(brand_data);
    getStripe()
  }, []);
  function AddProduct({ open, onClose, handleAlert }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (newState) => {
      // e.preventDefault();

      setProcessing(true);
      const user = await localforage.getItem("brand");

      // console.log(data)
      // alert(loc_data.location_id)
      // var loc_id = loc_id;
      const offer = props.data;
      console.log(offer);
      if (stripe_data == true) {
        if (offer) {
          const payload = {
            offer: offer,
            loc_id: loc_id,
            loc_name: loc_name,
            loc_add: loc_add,
            user_id: user._id,
          };
          const { response } = await AppService.snapshot.loadoffer({ payload });
          if (response) {
            setProcessing(false);
            props.handelchange();
            props.handleMessage(response.message);
            setOpenAddProduct(false);
          } else {
            setProcessing(false);
            props.handleMessage("something went wrong");
            props.handelchange();
            setOpenAddProduct(false);
          }
          // await axios
          //   .post(BASE_URL + "/snapshot/create_snapshot/loadoffer", payload)
          //   .then(async function (response) {
          //     console.log(response.data);
          //     setProcessing(false);

          //     props.handelchange();
          //     props.handleMessage(response.data.message);
          //     setOpenAddProduct(false);
          //   })
          //   .catch(function (error) {
          //     // console.log(error);
          //     // setProcessing(false);
          //     // props.handleMessage("something went wrong");
          //     // props.handelchange();
          //     // setOpenAddProduct(false);
          //   });
          // return 0;
        } else {
          setProcessing(false);

          setOpenAddProduct(false);
        }
      } else {
        setProcessing(false);
        onClose();

        handleAlert(
          "For Purchase a Bundle make Sure Your Card Must Be Registered!",
          "billing"
        );
      }
    };
    return (
      <MDModal open={open} onClose={onClose} className={"buy-modal"}>
        <div>
          <form className="form">
            <div className="banner"></div>
            <label className="title">Get Your Offer Loaded</label>
            <label className="title-main">{props.data.name}</label>

            <div className="benefits">
              <span>Description</span>
              <ul>
                <li>
                  <span>{props.data.description}</span>
                </li>
              </ul>
            </div>
            {props.data.setup_fee != "" && props.data.setup_fee != undefined ? (
              <div className="benefits">
                <span>SetUp Fee</span>
                <ul>
                  <li>
                    <span>
                      {location_settings.currency_sign} {props.data.setup_fee}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            {props.data.management_sub != "" &&
            props.data.management_sub != undefined ? (
              <div className="benefits">
                <span>Management price</span>
                <ul>
                  <li>
                    <span>
                      {location_settings.currency_sign}{" "}
                      {props.data.management_sub?.substr(
                        0,
                        props.data.management_sub?.indexOf("(")
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            <label className="title-main">Including Apps</label>
            {props.data.apps.map((item, key) =>
              props.purchased_apps.findIndex(
                (e) =>
                  e.app_id ===
                  item.value.substring(
                    item.value.indexOf("(") + 1,
                    item.value.indexOf(")", item.value.indexOf("("))
                  )
              ) > -1 ? (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  width="80%"
                >
                  <div className="benefits">
                    <span>Name</span>
                    <ul>
                      <li>
                        <span>
                          {item.label}{" "}
                          {varifyApp(
                            item.value.substring(
                              item.value.indexOf("(") + 1,
                              item.value.indexOf(")", item.value.indexOf("("))
                            )
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {props.data.type != "package"}
                  <div className="benefits">
                    <span>Price</span>
                    <ul>
                      <li>
                        {props.data.type != "package" ? (
                          <span>
                            {location_settings.currency_sign}{" "}
                            {props.purchased_apps[
                              props.purchased_apps.findIndex(
                                (e) =>
                                  e.app_id ===
                                  item.value.substring(
                                    item.value.indexOf("(") + 1,
                                    item.value.indexOf(
                                      ")",
                                      item.value.indexOf("(")
                                    )
                                  )
                              )
                            ]?.selling_price?.value.substr(
                              0,
                              props.purchased_apps[
                                props.purchased_apps.findIndex(
                                  (e) =>
                                    e.app_id ===
                                    item.value.substring(
                                      item.value.indexOf("(") + 1,
                                      item.value.indexOf(
                                        ")",
                                        item.value.indexOf("(")
                                      )
                                    )
                                )
                              ]?.selling_price?.value.indexOf(" ")
                            )}
                          </span>
                        ) : (
                          <span>Not Applicable</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </MDBox>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  width="80%"
                >
                  <div className="benefits">
                    <span>Name</span>
                    <ul>
                      <li>
                        <span>{item.label}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="benefits">
                    <span>Price</span>
                    <ul>
                      <li>
                        {props.data.type != "package" ? (
                          <span>
                            {location_settings.currency_sign}{" "}
                            {parseFloat(
                              item.value.substr(0, item.value.indexOf(" "))
                            ) * 3}
                          </span>
                        ) : (
                          <span>Not Applicable</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </MDBox>
              )
            )}

            <div class="modal--footer">
              <label class="price">
                <sup>{location_settings.currency_sign}</sup>
                {props.data.type == "package"
                  ? props.data.superadmin_prices.selling_price?.value?.substr(
                      0,
                      props.data.superadmin_prices.selling_price?.value?.indexOf(
                        " "
                      )
                    )
                  : MakePrice(props.data)}
              </label>
              <MDButton
                loading={processing}
                disabled={processing}
                class="upgrade-btn"
                type="button"
                onClick={handleSubmit}
              >
                Confirm to Buy
              </MDButton>
            </div>
          </form>
        </div>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  // const handleAlert = async (newState) => {
  //   const localBrand = await localforage.getItem("loc_data");
  //   setState({ ...state, open: true });
  //   setTimeout(() => {
  //     navigate(
  //       `/store/${type}/${localBrand.location_id}/${loc_name}/${loc_add}/agency/${localBrand.agency_id}/settings`
  //     );
  //   }, 2000);
  // };
  return (
    <>
      {props?.type == "iframe_page" ? (
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          onClick={handlemodal}
          sx={{ px: 2, py: 1, width: "60%" }}
          fullwidth
        >
          Buy Bundle ({props.data.name})
        </MDButton>
      ) : (
        <MDButton
          variant="outlined"
          size="small"
          color="primary"
          className="purchase-btn"
          onClick={handlemodal}
        >
          {props.label}
        </MDButton>
      )}
      <AddProduct
        open={openAddProduct}
        onClose={closeAddProduct}
        handleAlert={props.handleAlert}
      />
    </>
  );
};

export default BuyModal;
