/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, active, agency_data, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const primary = "linear-gradient(195deg, #EC407A, #D81B60)";
  const dark = "linear-gradient(195deg, #42424a, #191919)";
  const info = "linear-gradient(195deg, #49a3f1, #1A73E8)";
  const success = "linear-gradient(195deg, #66BB6A, #43A047)";
  const warning = "linear-gradient(195deg, #FFA726, #FB8C00)";
  const error = "linear-gradient(195deg, #EF5350, #E53935)";
  if (agency_data.sideNavColor == "primary") {
    document.documentElement.style.setProperty("--logo-color", primary);
  } else if (agency_data.sideNavColor == "dark") {
    document.documentElement.style.setProperty("--logo-color", dark);
  } else if (agency_data.sideNavColor == "info") {
    document.documentElement.style.setProperty("--logo-color", info);
  } else if (agency_data.sideNavColor == "success") {
    document.documentElement.style.setProperty("--logo-color", success);
  } else if (agency_data.sideNavColor == "warning") {
    document.documentElement.style.setProperty("--logo-color", warning);
  } else if (agency_data.sideNavColor == "error") {
    document.documentElement.style.setProperty("--logo-color", error);
  } else if (agency_data.sideNavColor == undefined) {
    document.documentElement.style.setProperty("--logo-color", info);
  } else {
    document.documentElement.style.setProperty(
      "--logo-color",
      agency_data.sideNavColor
    );
  }
  if (agency_data?.Customization?.Sidnav?.sideNavText == "primary") {
    document.documentElement.style.setProperty("--text-color", primary);
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == undefined) {
    document.documentElement.style.setProperty("--text-color", "white");
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == "dark") {
    document.documentElement.style.setProperty("--text-color", dark);
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == "info") {
    document.documentElement.style.setProperty("--text-color", info);
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == "success") {
    document.documentElement.style.setProperty("--text-color", success);
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == "warning") {
    document.documentElement.style.setProperty("--text-color", warning);
  } else if (agency_data?.Customization?.Sidnav?.sideNavText == "error") {
    document.documentElement.style.setProperty("--text-color", error);
  } else {
    document.documentElement.style.setProperty(
      "--text-color",
      agency_data.sideNavText
    );
  }
  if (agency_data?.Customization?.Cards?.background == undefined) {
    document.documentElement.style.setProperty("--cards-color", info);
  } else {
    document.documentElement.style.setProperty(
      "--cards-color",
      agency_data?.Customization?.Cards?.background
    );
  }
  if (agency_data?.Customization?.Cards?.boxShadow == undefined) {
  } else {
    document.documentElement.style.setProperty(
      "--cards-box-shadow",
      agency_data?.Customization?.Cards?.boxShadow
    );
  }
  if (agency_data?.Customization?.Cards?.bodyColor != undefined) {
    document.documentElement.style.setProperty(
      "--card-Bg",
      agency_data?.Customization?.Cards?.bodyColor
    );
  }
  if (agency_data?.Customization?.Cards?.titleColor != undefined) {
    document.documentElement.style.setProperty(
      "--snapshot-title",
      agency_data?.Customization?.Cards?.titleColor
    );
  }
  if (agency_data?.Customization?.Cards?.disTextColor != undefined) {
    document.documentElement.style.setProperty(
      "--snapshot-des-text",
      agency_data?.Customization?.Cards?.disTextColor
    );
  }
  if (agency_data?.Customization?.Cards?.disHeadingColor != undefined) {
    document.documentElement.style.setProperty(
      "--snapshot-des-heading",
      agency_data?.Customization?.Cards?.disHeadingColor
    );
  }
  if (agency_data?.Customization?.Cards?.FontFamily != undefined) {
    document.documentElement.style.setProperty(
      "--card-font-family",
      agency_data?.Customization?.Cards?.FontFamily
    );
  }
  if (agency_data?.Customization?.Sidnav?.FontFamily != undefined) {
    document.documentElement.style.setProperty(
      "--sidnav-font-family",
      agency_data?.Customization?.Sidnav?.FontFamily
    );
  }
  if (agency_data?.Customization?.Body?.background != undefined) {
    document.documentElement.style.setProperty(
      "--theme-Bg",
      agency_data?.Customization?.Body?.background
    );
  }
  if (agency_data?.Customization?.Cards?.PurchaseButton != undefined) {
    document.documentElement.style.setProperty(
      "--PurchaseButton",
      agency_data?.Customization?.Cards?.PurchaseButton
    );
  }
  if (agency_data?.Customization?.Cards?.SnapshotButton != undefined) {
    document.documentElement.style.setProperty(
      "--SnapshotButton",
      agency_data?.Customization?.Cards?.SnapshotButton
    );
  }
  if (agency_data?.Customization?.navbar?.background != undefined) {
    document.documentElement.style.setProperty(
      "--NavbarBg",
      agency_data?.Customization?.navbar?.background
    );
  }
  var sidenavColor = "info";
  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, {
              transparentSidenav,
              whiteSidenav,
              darkMode,
              active,
            })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  agency_data: PropTypes.array,
};

export default SidenavCollapse;
