import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

export default function PositionedSnackbar({ text, state }) {
  //   const [state, setState] = React.useState({
  //     open: false,
  //     vertical: "top",
  //     horizontal: "center",
  //   });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    // setState({ ...newState, open: true });
  };

  const handleClose = () => {
    // setState({ ...state, open: false });
  };

  return open ? (
    <Box sx={{ width: 700 }}>
      <Snackbar
        bgWhite
        color={"black"}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={text}
        key={vertical + horizontal}
      />
    </Box>
  ) : (
    <></>
  );
}
